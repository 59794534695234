<template lang="pug">
button.Ui2Boton(@click.prevent="emitirClickConCalma"
	@mousedown="emitirSiCorresponde"
	@touchstart.passive="detectorToque"
	:class="{ cargando, preIcono: slots.preIcono, postIcono: slots.postIcono, simetrico, oscuro: unref(TemaOscuroAPI.activo) }"
	:disabled="disabled")

	.elBorde
	// Indicador de componente en dev

	.contenidoBoton.ffrn.gap05em(:class="{ alCentro }")
		.zonaIcono.preIcono.fa00.aic(v-if="cargando || slots.preIcono || (slots.postIcono)")

			div(v-if="cargando")
				UiLoader

			div(v-else-if="slots.preIcono")
				slot(name="preIcono")

		.contenido(v-if="slots.default")
			slot

		.zonaIcono.postIcono.fa00.minw1(v-if="(simetrico && slots.preIcono) || slots.postIcono || cargando")
			div(v-if="slots.postIcono")
				slot(name="postIcono")
			div(v-else-if="cargando")
				.w1.h1
</template>

<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		cargando?: boolean
		alCentro?: boolean
		simetrico?: boolean
		intocable?: boolean
		disabled?: boolean
		emitirAlPresionar?: boolean
	}>(),
	{
		cargando: false,
		alCentro: false,
		simetrico: false,
		intocable: false,
		disabled: false,
		emitirAlPresionar: false
	}
)
const emit = defineEmits(['click'])

defineComponent({ name: 'Ui2Boton' })

const slots = useSlots()

const { cargando, alCentro, simetrico, disabled } = toRefs(props)

function emitirClick(event: MouseEvent) {
	if (unref(props.intocable))
		return
	if (unref(props.cargando)) {
		event.preventDefault()
		return
	}
	emit('click', event)
}

const emitirClickConCalma = debounce(emitirClick, 300, { leading: true, trailing: false })

function emitirSiCorresponde(event: MouseEvent) {
	if (!unref(props.emitirAlPresionar))
		return
	event.preventDefault()
	// emitirClick(event)
	emitirClickConCalma(event)
}
</script>

<style lang="sass">
@use 'sass:color'
@use "sass:math"
@use '@base/sass/comun'
.Ui2Boton
	text-align: center
	position: relative
	z-index: 1
	cursor: pointer
	line-height: 1.4

	outline: 0
	border: 0 solid transparent

	&::before
		z-index: 0
	&::after
		z-index: 1
	&::before,
	&::after
		content: ''
		+comun.maximizar
		border-radius: inherit
		transition: inherit

	&.preIcono:not(.postIcono):not(.anchoComun):not(.simetrico)
		.postIcono
			display: none
	&.postIcono:not(.preIcono):not(.anchoComun):not(.simetrico)
		.preIcono
			display: none
	.contenidoBoton
		position: relative
		z-index: 2
		&.alCentro
			justify-content: center
			.contenido
				flex: auto 0 1
				display: flex
				align-items: center
				line-height: 1
		.contenido
			flex: auto 1 1
			max-width: 100%


	.zonaIcono
		min-width: 1em
		min-height: 1em
		line-height: 0
	&.vertical
		.contenidoBoton
			flex-direction: column
			justify-content: center
			align-items: center

		.zonaIcono
			&.preIcono
				margin-bottom: 0.2em
				margin-right: 0
			&.postIcono
				margin-top: .5em
				margin-left: 0

	&:not(.sinPadding)
		$min: #{math.div(14,17)}rem
		$lado: #{math.div(20,17)}rem
		padding: $min $lado
		&.circular,
		&.cuadrado
			padding: $min
		&.chico
			$min: #{math.div(7,17)}rem
			$lado: #{math.div(14,17)}rem
			padding: $min $lado
			&.circular,
			&.cuadrado
				padding: $min
		&.mini
			$min: #{math.div(4,17)}rem
			$lado: #{math.div(10,17)}rem
			padding: $min $lado
			&.circular,
			&.cuadrado
				padding: $min
		&.vertical
			padding-top: 1em
			padding-bottom: 1em

	// * Radio

	&:not(.radioS):not(.radioL):not(.sinRadio)
		border-radius: .75rem
		&.rectangular
			border-radius: .4rem
		&.redondeado,
		&.circular,
		&.chico,
		&.mini
			border-radius: 10em

	// * Altura y ancho mínimos

	&:not(.sinMinimos)
		min-height: #{math.div(50,17)}rem
		min-width: #{math.div(50,17)}rem
		&.chico
			min-height: #{math.div(34,17)}rem
			min-width: #{math.div(34,17)}rem
		&.mini
			min-height: #{math.div(28,17)}rem
			min-width: #{math.div(28,17)}rem

	// Font sizes
	&:not(.sinFz)
		+comun.textoDelBody
		&.chico,
		&.mini
			+comun.subencabezado
			&.circular
				line-height: 1


	&.cuadrado,
	&.circular
		.contenido
			display: none
		.postIcono
			display: none
	&.circular
		line-height: 1
		.contenidoBoton
			justify-content: center
			align-items: center
		.zonaIcono
			min-width: 0

			&.preIcono
				margin-right: 0
			&.postIcono
				margin-left: 0

	&.cuadrado
		.contenidoBoton
			justify-content: center
			align-items: center
		.zonaIcono
			min-width: 0
			&.preIcono
				margin-right: 0
			&.postIcono
				margin-left: 0

@keyframes moverFondo 
	0% 
		background-position: 0% 0
	100% 
		background-position: 500% 0


// Colores
.Ui2Boton
	background-color: transparent
	&:not([class$="Fondo"]),
	&:not(.alAire)
		background-color: transparent
		background-color: var(--botonFondo)
		&::before
			background-color: inherit
	&:not([class$="Color"])
		color: var(--botonColor)
		&::after
			content: ''
			z-index: 4

	&.body
		&Color
			color: var(--bodyColor)
		&Fondo::before
			background-color: var(--bodyFondo)
	&.blanco
		&Color
			color: white
		&Fondo
			// &Fondo::before
			background-color: white
	&.negro
		&Color
			color: black
		&Fondo
			// &Fondo::before
			background-color: black
	&.primario
		&Color
			color: var(--primarioColor)
		&Fondo
			// &Fondo::before
			background-color: var(--primarioFondo)
	&.link
		&Color
			color: var(--linkColor)
		&Fondo
			// &Fondo::before
			background-color: var(--linkFondo)
	&.activo
		&Color
			color: var(--activoColor)
		&Fondo
			// &Fondo::before
			background-color: var(--activoFondo)
	&.exito
		&Color
			color: var(--exitoColor)
		&Fondo
			// &Fondo::before
			background-color: var(--exitoFondo)
	&.atencion
		&Color
			color: var(--atencionColor)
		&Fondo
			// &Fondo::before
			background-color: var(--atencionFondo)
	&.peligro
		&Color
			color: var(--peligroColor)
		&Fondo
			// &Fondo::before
			background-color: var(--peligroFondo)

	&.alAire
		&
			background-color: transparent

	.elBorde
		+comun.maximizar
		+comun.bradi
		border: 1px solid #00000000
	&.bordeado,
	&.bordeadoSuave
		.elBorde
			z-index: 2
			border-color: currentColor
	&.bordeado,
	&.bordeadoSuave
		&.bordeIncoloro
			&::before
				filter: saturate(0)

	&.casiAlAire
		&::before
			opacity: .5
		// &.oscuro::before
		// 	opacity: .25
		&.casi
			&::after
				opacity: .25
			&.oscuro::before
				opacity: .25
	&.bordeado,
	&.bordeadoSuave
		&::before
			opacity: .4
		&.oscuro::before
			opacity: .3
		&.semiBordeado
			&::before
				opacity: .069
			// &.oscuro::before
			// 	opacity: .05
	&.bordeadoSuave
		&::before
			opacity: .1
		&.oscuro::before
			opacity: .3

	&[disabled],
	&.desactivado,
	&:disabled
		filter: saturate(.5) opacity(20%)
		cursor: not-allowed
	&.cargando
		opacity: .8
		cursor: wait
	&.gradiente
		&Color
			color: var(--gradienteColor)
		&Fondo::before
			background: var(--gradienteFondo)
		&Fondo.cargando
			opacity: 1
			&::before
				background: var(--gradienteFondoContinuo)
				background-size: 500% 100%
				background-repeat: repeat
				animation: moverFondo 25s linear infinite
				animation-iteration-count: infinite

// Transiciones de cambio de estado
.Ui2Boton
	transform: translateY(0px)
	transition: all .3s ease
	&:focus-visible
		outline: .3rem solid currentColor
	&:not(:disabled):not(.cargando):not(.inmovil)
		$distanciaArriba: .2rem
		+comun.hoverDeMouse
			&
				transition-duration: .12s

			// Hover de mouse
			&:hover
				transition-property: all
				transform: translateY(-#{$distanciaArriba})
				&::after
					transform: translateY(#{$distanciaArriba})
				&::before
					filter: brightness(.975)
			// Siendo cliqueado
			&:active:hover
				transform: translateY(0)
				&::after
					filter: brightness(1) saturate(1.05)
				.contenidoBoton
					filter: brightness(.95)
			// * Oscuro
			&.oscuro:hover
				&::after
					filter: brightness(.995)
				.contenidoBoton
					filter: brightness(1.1)
				&:active // Clicando
					&::after
						filter: brightness(.97) saturate(1.1)
					.contenidoBoton
						filter: brightness(1.1) saturate(1.1)

</style>
