import { ZodError, z } from 'zod'
import type { FastifyReply } from 'fastify'
import chalk from 'chalk'
import { version } from '../package.json'

const OpcionesZod = z.object({
	error: z.any(),
	datos: z.any().optional(),

	origen: z.string().optional(),
	alertar: z.boolean().optional(),

	respuesta: z
		.object({
			code: z.number().optional(),
			codigo: z.string(),
			desconectar: z.literal(true).optional(),
			datos: z.any()
		})
		.optional()
})
type Opciones = z.infer<typeof OpcionesZod>
export class ErrorAumentado extends Error {
	version: string
	trazado: string[] = []

	alertar = true

	override cause?: Error
	origen?: string

	datos?: any

	respuesta?: {
		code?: number
		codigo: string
		datos?: any,
		desconectar?: true
	}

	constructor(message: string, opciones?: Opciones) {
		super(message)
		this.version = version
		this.name = 'ErrorAumentado'
		this.alertar = ((): boolean => {
			if (opciones) {
				if (typeof opciones.alertar !== 'undefined')
					return opciones.alertar
				if (!opciones.respuesta)
					return true
				return false
			}
			else { return true }
		})()

		if (message === 'usuarioEliminado') {
			this.respuesta = {
				code: 409,
				codigo: 'cuentaEliminada'
			}
		}

		if (opciones) {
			if (opciones.origen) {
				this.origen = opciones.origen
				this.trazado.push(opciones.origen)
			}
			this.cause = opciones.error

			if (opciones.error instanceof ZodError && this.alertar) {
				console.error('\n', chalk.bgRed.bold(' Error de parseo '))
				// mostrar stack

				console.error('\nStack: ', this.stack)

				// console.error('issues \n', inspect(opciones.error.issues))
				const issues = opciones.error.issues
				for (const [issueID, issue] of Object.entries(issues)) {
					const {
						path,
						code,
						message,
						expected,
						received
					} = Object.assign({
						expected: '',
						received: ''
					}, issue)
					console.error(`Issue ${issueID} @ ${path.join(' . ')}\n`, `expected: ${expected}; received: ${received}\n`, `code: ${code}; message: ${message}`)
				}
				if (opciones.datos)
					console.error('datos: ', opciones.datos)
			}

			if (opciones.error) {
				const { name, message } = opciones.error
				console.log({ name, message })
				if (name === 'SequelizeConnectionRefusedError') {
					this.message = 'conexionFallidaSDB'
					//
					opciones.respuesta = {
						code: 500,
						codigo: 'conexionFallidaSDB'
					}
					opciones.alertar = true
				}
				if (name === 'MongoNetworkError') {
					this.message = 'conexionFallidaDB'
					//
					opciones.respuesta = {
						code: 500,
						codigo: 'conexionFallidaDB'
					}
					opciones.alertar = true
				}
			}

			if (opciones.datos)
				this.datos = opciones.datos

			if (opciones.respuesta)
				this.respuesta = opciones.respuesta
			else if (!opciones.respuesta && this.alertar)
				console.error(chalk.red('ErrorAumentado: '), chalk.yellow(message))
		}
	}

	retornarAlCliente<T extends FastifyReply>(reply: T) {
		if (this.alertar)
			this.alertarlo()

		if (this.respuesta) {
			const respuesta = this.respuesta

			if (respuesta.code)
				reply.code(respuesta.code).send({ ok: false, error: respuesta.codigo, datos: respuesta.datos, desconectar: respuesta.desconectar })

			return reply.code(200).send({ ok: false, error: respuesta.codigo, datos: respuesta.datos, desconectar: respuesta.desconectar })
		}
		console.error('Respondiendo con 500: ErrorInesperado')
		return reply.code(500).send({ ok: false, error: 'ErrorInesperado' })
	}

	trazar(fx: string) {
		this.trazado.push(fx)
		if (!this.alertar)
			return this
		console.log('  Agregado al trazado: ', fx)
		return this
	}

	alertarlo() {
		if (this.cause instanceof ZodError)
			return
		console.error('-------------------')
		console.error(
			chalk.red('\n ALERTA '),
			chalk.yellow('ALERTA'),
			chalk.red(' ALERTA '),
			chalk.yellow('ALERTA')
		)
		console.error(this)
		console.error(
			chalk.red(' ALERTA '),
			chalk.yellow('ALERTA'),
			chalk.red(' ALERTA '),
			chalk.yellow('ALERTA')
		)
		console.error('-------------------')
	}

	// trazar(fx: string) {
	// 	console.log('  Agregado al trazado: ', fx)
	// 	this.trazado.push(fx)
	// 	return this
	// }
}
