/* eslint-disable @typescript-eslint/no-explicit-any */

import { z } from 'zod'
import * as Comlink from 'comlink';
import type { AxiosRequestConfig } from 'axios';
import rosetta from '@base/lib/rosetta'
import { ErrorAumentado } from '@base/lib/error'
import { ErrorNotificableZod, i18nIconos, i18nTapi } from '~/lib/erroresNotificables'
import { GimnasioZod, PerfilEnGimnasioZod, UsuarioZod } from '@comun/types';
import type { APIWorker } from '@base/types/axiosWorker';

export function HeadersConAuth(tkn?: string): Record<string, string | number> {
	const elToken = tkn ?? unref(token)
	if (!elToken)
		throw new ErrorAumentado('noHayToken', { origen: 'HeadersConAuth' })

	const headersBase = HeadersBase()
	return {
		...headersBase,
		Authorization: `Bearer ${token.value}`
	}
}

export function HeadersBase(): Record<string, string | number> {

	const headers: {
		[key: string]: string
	} = {
		'Accept': 'application/json',
		'GoTs-App': contextoApp.appConfig.appID,
		'GoTs-Ambiente': contextoApp.buildConfig.modo,
		'GoTs-Dispositivo': contextoApp.buildConfig.dispositivo,
		'GoTs-Version': contextoApp.buildConfig.version,
	}

	const gimID = unref(gimnasioID)
	const gim = unref(gimnasio)
	const usr = unref(usuario)
	const peg = unref(perfilEnGimnasio)

	if (gimID) headers['GoTs-Gimnasio'] = gimID
	if (gim && gim.marcaDeTiempo) headers['mdt-gim'] = gim.marcaDeTiempo
	if (usr && usr.marcaDeTiempo) headers['mdt-usr'] = usr.marcaDeTiempo
	if (peg && peg.marcaDeTiempo) headers['mdt-peg'] = peg.marcaDeTiempo

	// console.log('HeadersBase', headers, {
	// 	gim: !!gim,
	// 	usr: !!usr,
	// 	peg: !!peg,
	// })

	return headers
}

const i18nAxios = rosetta({
	errorDeRed: {
		es: 'Error de red',
		en: 'Network error',
		pt: 'Erro de rede'
	},
	sinRespuesta: {
		es: 'Sin respuesta',
		en: 'No response',
		pt: 'Sem resposta'
	},
	malaConfiguracion: {
		es: 'Mala configuración',
		en: 'Bad configuration',
		pt: 'Má configuração'
	},
	noSePudoConectarConAPI: {
		es: 'No se pudo conectar con API',
		en: 'Could not connect to API',
		pt: 'Não foi possível conectar à API'
	},
	noAutorizado: {
		es: 'No autorizado',
		en: 'Unauthorized',
		pt: 'Não autorizado'
	},
	solicitudRechazada: {
		es: 'Solicitud rechazada',
		en: 'Request rejected',
		pt: 'Solicitação rejeitada'
	}
})

const RespuestaConErrorZod = z.object({
	ok: z.literal(false),
	error: z.string(),
	desconectar: z.literal(true).optional(),
})

const appNoAutorizadaRef = ref(false)
export const appNoAutorizada = computed(() => appNoAutorizadaRef.value)

const appNoConectadaSQLRef = ref(false)
export const appNoConectadaSQL = computed(() => appNoConectadaSQLRef.value)

const appNoConectadaMongoDBRef = ref(false)
export const appNoConectadaMongoDB = computed(() => appNoConectadaMongoDBRef.value)

export function ManejadorErroresAxios(error: { error: string, status?: number, response?: any }) {
	consolo.log('\n\nManejadorErroresAxios error', error);

	// Verificar si es un error de red
	if (error.error.includes('Network Error')) {
		notificadorEnApp.error({
			titulo: i18nAxios('errorDeRed'),
			texto: i18nAxios('noSePudoConectarConAPI'),
			codigo: 'noSePudoConectarConAPI',
			duracionSegundos: 5,
			icono: 'arcticons:trexrunner',
			iconoMedida: '3rem'
		});
		throw new ErrorAumentado('sinConexion');
	}

	// Verificar si hay respuesta disponible
	if (!error.response) {
		throw new ErrorAumentado('sinRespuesta');
	}

	const errorParseo = RespuestaConErrorZod.safeParse(error.response.data);

	if (!errorParseo.success) {
		throw new ErrorAumentado('respuestaConErrorMalParseada', { datos: { responseData: error.response.data } });
	}

	const codigo = errorParseo.data.error;

	if (codigo === 'appNoAutorizada') {
		appNoAutorizadaRef.value = true;
	}

	const errorIDparseo = ErrorNotificableZod.safeParse(codigo)
	const errorID = errorIDparseo.success ? errorIDparseo.data : null
	if (errorID) {
		if (['conexionFallidaSDB', 'conexionRechazadaSDB'].includes(errorID)) {
			appNoConectadaSQLRef.value = true
		}

		if (codigo === 'conexionFallidaDB') {
			appNoConectadaMongoDBRef.value = true
		}

		if (['conexionFallidaSDB', 'conexionRechazadaSDB', 'conexionFallidaDB'].includes(errorID)) {
			notificadorEnApp.error({
				titulo: i18nTapi('appDesactivada'),
				texto: i18nTapi(errorID),
				codigo: errorID,
				icono: i18nIconos[errorID],
			})
		} else {
			// notificadorEnApp.error({
			console.error({
				titulo: 'Notificacion temporal para debug',
				texto: i18nTapi(errorID),
				codigo: errorID,
				icono: i18nIconos[errorID]
			})
		}
	}


	if (codigo === 'gimNoAutorizado') {
		AppAPI.DesconectarGimnasio();
	}

	if (codigo === 'cuentaEliminada') {
		UsuarioAPI.CerrarSesion();
	}

	if (['usuarioNoRegistrado', 'usuarioNoAutorizado'].includes(codigo)) {
		const i18n = TrosettaAPI.crear({
			seHaCerradoLaSesion: {
				es: 'Se ha cerrado la sesión',
				en: 'Session has been closed',
				pt: 'A sessão foi encerrada'
			},
			usuarioNoRegistrado: {
				es: 'Usuario no encontrado',
				en: 'User not found',
				pt: 'Usuário não encontrado'
			},
			usuarioNoAutorizado: {
				es: 'Usuario no autorizado',
				en: 'Unauthorized user',
				pt: 'Usuário não autorizado'
			},
			seHaImpedidoElAcceso: {
				es: 'Se ha impedido el acceso',
				en: 'Access has been denied',
				pt: 'Acesso foi negado'
			}
		});
		notificadorEnApp.error({
			titulo: i18n(codigo === 'usuarioNoRegistrado' ? 'usuarioNoRegistrado' : 'usuarioNoAutorizado'),
			texto: errorParseo.data.desconectar ? i18n('seHaCerradoLaSesion') : i18n('seHaImpedidoElAcceso'),
			codigo,
			duracionSegundos: 3,
			icono: 'solar:ghost-outline',
			iconoMedida: '3rem'
		});
		UsuarioAPI.CerrarSesion();
		throw new ErrorAumentado('sinConexion');
	}

	return errorParseo.data;
}


// Cargar el Web Worker compilado desde el servidor
const workerUrl = '/axiosWorker.js';

let worker: Worker | null = null;
let api: Comlink.Remote<APIWorker> | null = null;

// Inicializar el Worker
const initWorker = () => {
	if (!worker) {
		worker = new Worker(workerUrl);
		api = Comlink.wrap(worker);
	}
};

// Función para hacer la solicitud HTTP usando `axios(config)`
const requestInWorker = async (config: AxiosRequestConfig) => {
	initWorker();

	try {
		const response = await api?.solicitar(config); // Pasar toda la configuración al Worker
		console.log('axiosWorkerAPI response', response)

		// Validar la respuesta
		if (!response) {
			throw new ErrorAumentado('sinRespuesta');
		}
		// const data = response.data;
		return response

		// else if (response.success === false) {
		// 	console.error('response.success === false');
		// 	console.error('Ocurrió un error, response.success === false, response.error:', response.error);
		// 	throw response;  // Lanza el error serializado
		// }
		// return response;
	} catch (error: any) {
		// El error será el que viene serializado desde el Worker
		console.error('Ocurrió un error notado por axiosWorker:', error);
		// Pasar el error directamente al manejador
		throw error;  // Re-lanzar el error después de manejarlo
	}
};

// API principal similar a Axios
export const axiosWorker = async (config: AxiosRequestConfig) => {
	// Limpiar errores
	appNoAutorizadaRef.value = false
	appNoConectadaSQLRef.value = false
	appNoConectadaMongoDBRef.value = false

	const recibidoDelWorker = await requestInWorker(config)
		.then(r => r.data)
		.catch(ManejadorErroresAxios);



	const parseado = z.object({
		usuario: UsuarioZod.optional(),
		perfilEnGimnasio: PerfilEnGimnasioZod.optional(),
		gimnasio: GimnasioZod.optional(),
		desconectar: z.literal(true).optional(),
	})
		.safeParse(recibidoDelWorker)

	if (parseado.success) {
		// Integrar la info que esté presente.'
		const resultado = parseado.data
		if (resultado.gimnasio) GimnasioAPI.IntegrarGimnasio(resultado.gimnasio)
		if (resultado.usuario) UsuarioAPI.IntegrarUsuario(resultado.usuario)
		if (resultado.perfilEnGimnasio) PerfilEnGimnasioAPI.IntegrarPerfilEnGimnasio(resultado.perfilEnGimnasio)
		if (resultado.desconectar) UsuarioAPI.CerrarSesion()
	}

	return recibidoDelWorker
};
