import _ from 'lodash'
import { z } from 'zod'

import { InstanciaZod, ListaDeParticipantesZod } from '@comun/types'
import type { Gimnasio, Instancia, InstanciaSetIDs } from '@comun/types'


import { ErrorAumentado } from '@base/lib/error'

import { CalcularInstanciaDesdeID, CalcularInstanciaDesdeIDs, ConformarIntanciaID } from '@comun/instancias'

import { ErrorNotificableZod, i18nIconos, i18nTapi } from '../lib/erroresNotificables'
import { UsuariosAPI } from './usuarios'

const consoloRaiz = 'Lib TAPI Instancias'
const consoloColor = 'color: SteelBlue'

const _ReservabilidadZod = z.discriminatedUnion('reservable', [z.object({
	reservable: z.literal(true)
}), z.object({
	reservable: z.literal(false),
	motivo: z.string()
})])
type Reservabilidad = z.infer<typeof _ReservabilidadZod>

export function DeterminarReservabilidadTemporalDeInstancia(datos: {
	gimnasio: Gimnasio
	instancia: Instancia
}): Reservabilidad {
	const { gimnasio, instancia } = datos

	// Revisar que la reserva sea antes de que termine la clase
	const ahora = dayjs()
	const instanciaInicio = dayjs(instancia.fechaInicio)
	const instanciaFin = dayjs(instancia.fechaFin)

	if (instanciaFin.isBefore(ahora)) {
		return {
			reservable: false,
			motivo: 'sesionConcluida'
		}
	}

	// Hora de apertura
	const minsParaApertura = gimnasio.restricciones.reservaAperturaMins
	const limitarAperturaDeReservas = typeof minsParaApertura === 'number'
	if (limitarAperturaDeReservas) {
		// * minsParaApertura Puede ser 0
		const limiteApertura = instanciaInicio.subtract(minsParaApertura, 'm')

		if (ahora.isBefore(limiteApertura)) {
			return {
				reservable: false,
				motivo: 'limiteApertura'
			}
		}
	}

	// Antelacion minima
	const minsDeAntelacion = gimnasio.restricciones.reservaAntelacionMins
	const limiteAntelacion
		= minsDeAntelacion && instanciaInicio.subtract(minsDeAntelacion, 'm')
	if (minsDeAntelacion && ahora.isAfter(limiteAntelacion)) {
		return {
			reservable: false,
			motivo: 'minsDeAntelacion'
		}
	}

	// Minutos reservables tras inicio
	const minsReservablesTrasInicio = gimnasio.restricciones.reservaTrasInicioMins
	if (minsReservablesTrasInicio) {
		const limiteReservablesTrasInicio = dayjs(instancia.fechaInicio).add(minsReservablesTrasInicio, 'm')

		if (ahora.isAfter(limiteReservablesTrasInicio)) {
			consolo.log('ahora', ahora.format())
			consolo.log('limiteReservablesTrasInicio', limiteReservablesTrasInicio.format())
			return {
				reservable: false,
				motivo: 'reservaTrasInicioMins'
			}
		}
	}

	// --END-- Está en el plazo para reservar y hay cupos!
	return {
		reservable: true
	}
}

function CalcularInstanciasIDsEnFecha(fecha: Dayjs): string[] {
	consolo.log(`%c${consoloRaiz} CalcularInstanciasIDsEnFecha`, consoloColor)
	const gimnasio = unref(useGimnasio().gimnasio)
	if (!gimnasio)
		return []
	const timezone = gimnasio.perfilDelCentro.timezone
	if (!timezone)
		return []
	const clases = unref(useGimnasio().clases)
	if (!clases || !Object.values(clases).length)
		return []

	const diaSemana = fecha.isoWeekday()
	const fechaYMD = fecha.format('YYYY-MM-DD')

	const feriadosAno = gimnasio.feriados[fecha.format('YYYY')] || []
	const esFeriado = feriadosAno.includes(fechaYMD)
	if (esFeriado && !gimnasio.caracteristicas.mostrarHorariosEnFeriados)
		return []

	const instanciaIDs: string[] = []

	_.forEach(clases, (clase) => {
		if (!clase.activa)
			return
		const horariosEnFecha = Object.values(clase.horarios).filter((h) => {
			return h.diaSemana === diaSemana
		})

		horariosEnFecha.forEach((h) => {
			if (!h.activo)
				return

			const instanciaID = ConformarIntanciaID({ fechaYMD, claseID: clase.claseID, horarioID: h.horarioID })
			instanciaIDs.push(instanciaID)
		})
	})
	return instanciaIDs
}

// Store

const cacheDeInstanciasRef = ref<Record<string, Instancia>>({})
const descargandoInstanciasRef = ref<boolean>(false)

function IntegrarInstancias(instanciasRecibidas: Record<string, Instancia>): void {
	const integradas = lodash.assign({ ...(unref(cacheDeInstanciasRef)) }, instanciasRecibidas)
	consolo.log('integradas', integradas)
	// const integradas = unref(cacheDeInstanciasRef)
	// for (const instancia of Object.values(instanciasRecibidas))
	// 	integradas[instancia.instanciaID] = instancia


	cacheDeInstanciasRef.value = integradas
	return
}

export const instancias = computed((): Record<string, Instancia> => {
	return cacheDeInstanciasRef.value
})

export const descargandoInstancias = computed(() => {
	return unref(descargandoInstanciasRef)
})

// Funciones locales

async function ActualizarInstancias(
	instanciaSetIDsPorObtener: InstanciaSetIDs[]
): Promise<void> {
	const fx = 'ActualizarInstancias'
	// consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor, instanciaSetIDsPorObtener)
	const timerID = MiniID()
	consolo.time(timerID)
	try {
		await DescargarInstancias(instanciaSetIDsPorObtener)
	}
	catch (e) {
		consolo.error('error', e)
		throw e
	}
	finally {
		consolo.timeEnd(timerID)
		consolo.log(fx, 'fin/n')
	}
}

async function ActualizarInstanciasDeFecha(fecha: Dayjs): Promise<void> {
	const fx = 'ActualizarInstanciasDeFecha'
	consolo.log(fx)
	const timerID = MiniID()
	consolo.time(timerID)
	try {
		const gim = unref(useGimnasio().gimnasio)
		if (!gim)
			throw new ErrorAumentado(`${fx}: no hay gimnasio`)

		const instanciasIDs = CalcularInstanciasIDsEnFecha(fecha)
		const instancias = instanciasIDs.map(instanciaID =>
			CalcularInstanciaDesdeID({ gimnasio: gim, instanciaID })
		)

		await DescargarInstancias(instancias)
	}
	catch (e) {
		consolo.error('error', e)
		throw new ErrorAumentado(`${fx} falló`, { error: e })
	}
	finally {
		consolo.timeEnd(timerID)
		consolo.log(fx, 'fin/n')
	}
}

// Funciones de API

const instanciasPorDescargarRef = ref<Record<string, InstanciaSetIDs>>({})
const instanciaIDsEnDescargaRef = ref<string[]>([])
export const instanciaIDsEnDescarga = computed(() => instanciaIDsEnDescargaRef.value)

async function doDescargarInstancias(
	registroPorDescargar: Record<string, InstanciaSetIDs>
): Promise<void> {
	const fx = 'doDescargarInstancias'
	// consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)

	const instanciasIDs = _.map(registroPorDescargar, i => i.instanciaID)
	instanciaIDsEnDescargaRef.value = instanciasIDs

	const instanciasParams: Pick<InstanciaSetIDs, 'claseID' | 'fechaYMD' | 'horarioID'>[] = []

	for (const [_instanciaID, instanciaSet] of Object.entries(registroPorDescargar)) {
		const { fechaYMD, claseID, horarioID } = instanciaSet
		instanciasParams.push({ fechaYMD, claseID, horarioID })
	}

	try {
		const { gimnasio: gim } = useGimnasio()
		const gimnasio = unref(gim)
		if (!gimnasio)
			throw new ErrorAumentado(`${fx}: no hay gimnasio`)
		if (!instanciasParams.length)
			return

		descargandoInstanciasRef.value = true

		const headers = HeadersConAuth()
		const respuesta = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/instancias/porIDs`,
			method: 'post',
			headers,
			data: { instancias: instanciasParams }
		})

		const RespuestaInstanciasZodError = z.object({
			ok: z.literal(false),
			error: ErrorNotificableZod
		})

		const RespuestaInstanciasZodOk = z.object({
			ok: z.literal(true),
			instancias: z.record(z.string(), InstanciaZod),
			participantes: ListaDeParticipantesZod
		})

		const parserInstancias = z.discriminatedUnion('ok', [
			RespuestaInstanciasZodOk,
			RespuestaInstanciasZodError
		])
		const parseoInstancias = parserInstancias.safeParse(respuesta)

		if (!parseoInstancias.success) {
			const issues = parseoInstancias.error.issues
			consolo.error('Error al parsear instancias')
			consolo.error('issues', issues)
			// Reportar
			throw new ErrorAumentado('malParseoRespuesta', {
				datos: {
					parser: 'parseoInstancias'
				},
				error: parseoInstancias.error
			})
		}

		if (parseoInstancias.data.ok === false) {
			const errorID = parseoInstancias.data.error

			notificadorEnApp.atencion({
				titulo: i18nTapi('falloObtencionDeHorarios'),
				texto: i18nTapi(errorID),
				codigo: errorID,
				icono: i18nIconos[errorID]
			})
			throw new ErrorAumentado('resultadoNegativo', {
				error: parseoInstancias.data
			})
		}

		// Integrar instancias a caché
		// instanciasRecibidas = parseoInstancias.data.instancias
		InstanciasAPI.IntegrarInstancias(parseoInstancias.data.instancias)


		// Si vienen participantes, integrarlos a la cache de participantes
		const participantes = parseoInstancias.data.participantes
		if (!_.isEmpty(participantes))
			UsuariosAPI.IntegrarParticipantes({ gimnasio, participantes })
	}
	catch (e) {
		consolo.error('error', e)
		throw e
	}
	finally {
		descargandoInstanciasRef.value = false
		requestAnimationFrame(() => {
			instanciaIDsEnDescargaRef.value = _.difference(instanciaIDsEnDescarga.value, instanciasIDs)
		})
		// consolo.groupEnd()
	}
}

const DescargaPendientes = _.debounce(async (): Promise<void> => {
	
	const timerID = MiniID()
	console.time(timerID)
	const fx = 'DescargaPendientes'
	consolo.log(timerID, `%c${fx}`, consoloColor)
	try {
		const instanciasPorDescargar = unref(instanciasPorDescargarRef)
		instanciaIDsEnDescargaRef.value = Object.keys(instanciasPorDescargar)
		instanciasPorDescargarRef.value = {}
		await doDescargarInstancias(instanciasPorDescargar)
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		console.error(timerID, fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		instanciaIDsEnDescargaRef.value = []
		// console.timeEnd(timerID)
		consolo.log(fx, 'fin\n')
	}
}, 100, { leading: false, trailing: true })

async function DescargarInstancias(
	instanciasPorDescargar: InstanciaSetIDs[]
): Promise<void> {
	const fx = 'DescargarInstancias'
	// consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		// * Agregar instancias a la lista de instancias por descargar
		const porDescargar = unref(instanciasPorDescargarRef)
		const enDescarga = unref(instanciaIDsEnDescarga)
		for (const instanciaSet of instanciasPorDescargar) {
			const { fechaYMD, claseID, horarioID } = instanciaSet
			const instanciaID = ConformarIntanciaID({ fechaYMD, claseID, horarioID })
			// Si ya se está descargando, no hacer nada
			if (enDescarga.includes(instanciaID))
				continue

			porDescargar[instanciaID] = instanciaSet
		}
		instanciasPorDescargarRef.value = porDescargar

		// * Descargar via debounce
		await DescargaPendientes()
	}
	catch (e) {
		consolo.error(fx, 'error', e)
		throw e
	}
	// finally {
	// 	consolo.groupEnd()
	// }
}

// Interacciones

const ObtenerURLAccesoASesionOk = z.object({
	ok: z.literal(true),
	url: z.string()
})
const ObtenerURLAccesoASesionError = z.object({
	ok: z.literal(false),
	error: ErrorNotificableZod
})
const ObtenerURLAccesoASesionParser = z.discriminatedUnion('ok', [
	ObtenerURLAccesoASesionOk,
	ObtenerURLAccesoASesionError
])

async function AccederASesionOnline(datos: {
	reservaID: string
}): Promise<string> {
	const fx = 'InstanciasAPI.AccederASesionOnline'
	consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		const { reservaID } = datos
		const headers = HeadersConAuth()
		const r = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/reservas/accederASesionOnline`,
			method: 'post',
			headers,
			data: {
				reservaID
			}
		})


		const parseo = ObtenerURLAccesoASesionParser.safeParse(r)
		if (!parseo.success) {
			// Reportar
			throw new ErrorAumentado('malParseoRespuesta', {
				datos: {
					parser: 'ObtenerURLAccesoASesionParser'
				},
				error: parseo.error
			})
		}

		const datosRecibidos = parseo.data
		if (!datosRecibidos.ok) {
			const errorParseado = datosRecibidos.error

			notificadorEnApp.atencion({
				titulo: i18nTapi('falloElIngreso'),
				texto: i18nTapi(errorParseado),
				codigo: errorParseado,
				icono: i18nIconos[errorParseado]
			})
			throw new ErrorAumentado('resultadoNegativo', {
				error: datosRecibidos.error
			})
		}
		if (!datosRecibidos.url)
			throw new ErrorAumentado(`${fx}: no hay url`)
		return r.url
	}
	catch (e) {
		consolo.error('error', e)
		throw new ErrorAumentado(`${fx}: error`, { error: e })
	}
}

// == Limpiar ==

function Limpiar() {
	cacheDeInstanciasRef.value = {}
}

// == API ==

export const InstanciasAPI = {
	Limpiar,

	CalcularInstanciasIDsEnFecha,
	CalcularInstanciaDesdeIDs,
	CalcularInstanciaDesdeID,

	ActualizarInstancias,
	ActualizarInstanciasDeFecha,

	AccederASesionOnline,
	IntegrarInstancias
}


export function useInstancias() {
	return {
		instancias,
		instanciaIDsEnCarga: instanciaIDsEnDescarga,
		descargandoInstancias
	}
}
