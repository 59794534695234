import { z } from 'zod'

import {
	BooleanOFalsoZod,
	BooleanOVerdaderoZod,
	BooleanOpcionalZod,
	DiaSemanaZod,
	EmailZod,
	FechaOpcionalZod,
	FechaYMDOpcional,
	FechaYMDZod,
	FechaZod,
	HoraZod,
	IDNumericoOpcionalZod,
	IDNumericoZod,
	IDTextualOpcionalZod,
	IDTextualZod,
	NumeroOCeroZod,
	NumeroOpcionalZod,
	NumeroZod,
	PrecioOpcionalZod,
	StringOpcionalZod,
	StringZod,
	URLOpcionalZod,
	URLZod
} from './zodComun'
import { IdiomasDisponiblesZod } from './rosetta'

export const rolesStaff = [
	'coach',
	'admin',
	'recepcion',
	'head_coach',
	'super_admin'
] as const
export const RolStaffZod = z.enum(rolesStaff)
export const RolZod = z.union([RolStaffZod, z.literal('alumno')])

export function esRolStaff(role: string): role is typeof rolesStaff[number] {
	return (rolesStaff as readonly string[]).includes(role);
}

export type Rol = z.infer<typeof RolZod>

export const IdentidadesXPaisZod = z.object({
	cl: StringOpcionalZod,
	mx: StringOpcionalZod,
	br: StringOpcionalZod
})
export type IdentidadesXPais = z.infer<typeof IdentidadesXPaisZod>

export const SexoZod = z.enum(['hombre', 'mujer'])
export type Sexo = z.infer<typeof SexoZod>

export const PerfilZod = z.object({
	nombre: StringOpcionalZod,
	apellido: StringOpcionalZod,

	avatar: StringOpcionalZod,

	rut: StringOpcionalZod,

	fechaNacimiento: FechaYMDOpcional,
	sexo: SexoZod.optional(),
	estatura: NumeroOpcionalZod,
	
	talla: StringOpcionalZod,

	identidadesXPais: IdentidadesXPaisZod.optional()
})
export type Perfil = z.infer<typeof PerfilZod>

export const PreferenciasPerfilZod = z.object({
	idioma: IdiomasDisponiblesZod.optional().catch(undefined)
})

export const PermisosZod = z.object({
	// Alumno
	reservar: BooleanOFalsoZod,
	cancelarReserva: BooleanOFalsoZod,

	adquirirPlanes: BooleanOFalsoZod,
	verEstadisticasVideos: BooleanOFalsoZod,

	inscribirOtroGimnasio: BooleanOFalsoZod,

	// Profes
	registrarseGuiaDeSesion: BooleanOFalsoZod,
	eliminarseGuiaDeSesion: BooleanOFalsoZod,

	verListaDeAlumnos: BooleanOFalsoZod,
	agregarAlumno: BooleanOFalsoZod,
	quitarAlumno: BooleanOFalsoZod,

	marcarAsistencia: BooleanOFalsoZod,
	escanearAsistencia: BooleanOFalsoZod,
	registrarGuiasDeSesion: BooleanOFalsoZod,
	eliminarGuiasDeSesion: BooleanOFalsoZod,

	verSesionesPasadas: BooleanOFalsoZod,

	// Staff
	irAlAdmin: BooleanOFalsoZod,
	// Otros
	verProgramasEnPlanes: BooleanOFalsoZod,

	// Noticias
	verNoticias: BooleanOFalsoZod,
	crearYEditarNoticias: BooleanOFalsoZod,
	publicarNoticias: BooleanOFalsoZod,

	verGimnasioCancelado: BooleanOFalsoZod,

	verTodasLasRutinas: BooleanOFalsoZod,

	verPagosEliminados: BooleanOFalsoZod,
	verReservasEliminadas: BooleanOFalsoZod
})
export type Permisos = z.infer<typeof PermisosZod>

//

export const InstanciaSetIDsZod = z.object({
	instanciaID: IDTextualZod,
	claseID: IDTextualZod,
	horarioID: IDTextualZod,
	fechaYMD: FechaYMDZod
})
export type InstanciaSetIDs = z.infer<typeof InstanciaSetIDsZod>

export const InstanciaCalculadaZod = InstanciaSetIDsZod.extend({
	salaID: IDTextualOpcionalZod,

	fechaInicio: FechaZod,
	fechaFin: FechaZod,

	esFeriado: BooleanOFalsoZod
})
export type InstanciaCalculada = z.infer<typeof InstanciaCalculadaZod>

export const ReservaZod = z.object({
	creacion: FechaZod,
	actualizacion: FechaZod,
	eliminacion: FechaOpcionalZod,
	reservaID: IDTextualZod,
	bmID: IDNumericoZod,

	...InstanciaSetIDsZod.shape,
	instanciaID: IDTextualZod,

	lugarID: IDTextualOpcionalZod,
	utilizaCupo: BooleanOFalsoZod,
	fechaInicio: FechaZod,

	usuarioID: IDTextualZod,
	perfilEnGimnasioID: IDTextualZod.optional(),
	membresiaID: IDTextualOpcionalZod,
	planID: IDTextualOpcionalZod,
	// planID: IDTextualZod,
	pagoID: IDTextualOpcionalZod,

	cupoPagado: BooleanOpcionalZod,

	utilizaSabadoLibre: BooleanOpcionalZod,
	utilizaCupoIlimitado: BooleanOpcionalZod,

	asistenciaConfirmada: BooleanOpcionalZod,
	formularioRespondido: BooleanOpcionalZod,

	rating: NumeroOpcionalZod,
	comentario: StringOpcionalZod,

	urlIngreso: URLOpcionalZod,

	canal: StringOpcionalZod,

	gympassID: StringOpcionalZod,
	classpassID: StringOpcionalZod,
	fitpassID: StringOpcionalZod
})
export type Reserva = z.infer<typeof ReservaZod>

// Eliminacion

// export const EliminacionZod = z.object({
// 	fecha: FechaZod,
// 	dispositivo: z.string(),
// 	version: z.string(),
// 	appID: z.string(),
// 	gimnasioID: z.string()
// })
// export type Eliminacion = z.infer<typeof EliminacionZod>

export const ReservaEnPagoZod = ReservaZod.pick({
	reservaID: true,

	fechaYMD: true,
	claseID: true,
	horarioID: true,

	lugarID: true,
	utilizaCupo: true,
	fechaInicio: true,

	eliminacion: true
})
export type ReservaEnPago = z.infer<typeof ReservaEnPagoZod>

const StatsDeReservasZod = z.object({
	reservas: NumeroZod,
	cuposUsados: NumeroZod,
	diasReservados: z.array(FechaYMDZod)
})
// Periodos de cupos
export const PeriodoDeVigenciaDeCuposZod = z.object({
	periodoID: IDTextualZod,
	fechaInicio: FechaZod,
	fechaFin: FechaZod,
	// reservaIDs: z.array(StringZod),
	reservas: z.record(z.string(), ReservaEnPagoZod).catch({}),
	stats: StatsDeReservasZod
})
export type PeriodoDeVigenciaDeCupos = z.infer<typeof PeriodoDeVigenciaDeCuposZod>

const MedioDePagoZod = z.enum([
	'efectivo',
	'transferencia',
	'debito',
	'credito',
	'cheque',
	'khipu',
	'webpay',
	'mercadopago',
	'paypal',
	'stripe',
	'MACH',
	'no informa',
	'pago online'
])

// Plan configuraciones
export const PlanConfiguracionezZod = z.object({
	multiplesReservasPorSesion: BooleanOFalsoZod
})

// Plan
export const PlanZod = z.object({
	planID: IDTextualZod,
	activo: BooleanOFalsoZod,

	nombre: z.string(),
	descripcion: StringOpcionalZod,

	vigencia: z.enum([
		'Mensual',
		'Cuatrimestral',
		'Trimestral',
		'Semestral',
		'Anual',
		'Canje',
		'Complementario',
		'Personalizado',
		'Por Clase',
		'Prueba'
	]),

	precio: PrecioOpcionalZod,
	bajoSuscripcion: BooleanOFalsoZod,

	vender: BooleanOFalsoZod,
	mostrarEnTienda: BooleanOFalsoZod,
	permitirRenovacion: BooleanOFalsoZod,
	mostrarEnApp: BooleanOFalsoZod,
	destacado: BooleanOFalsoZod,

	maximoReservasDiarias: z.number(),
	cupos: z.number(),

	cupoPorDia: BooleanOFalsoZod,

	cuposIlimitados: BooleanOFalsoZod,
	cuposPlazo: z.enum(['semanal', 'mensual', 'personalizado', 'por clase', 'prueba']),
	cuposPlazoSemanaCalendario: BooleanOFalsoZod,
	sabadoLibre: BooleanOFalsoZod,

	vigenciaDias: z.number(),
	cuposAdicionales: z.number(),

	multiplesPagos: BooleanOFalsoZod,
	multiplesPagosID: StringOpcionalZod,
	multiplesPagosCantidad: z.number(),

	soloParaMiembrosExclusivos: BooleanOFalsoZod,
	paraReservaDeEspacios: BooleanOFalsoZod,

	urlContratacion: URLOpcionalZod, // URL de la tienda, se genera url con hashid al enviar a cliente

	programasIDs: z.array(IDTextualZod),

	configuraciones: PlanConfiguracionezZod.optional()
})
export type Plan = z.infer<typeof PlanZod>

export const RegistroDePlanesZod = z.record(z.string(), PlanZod)
export type RegistroDePlanes = z.infer<typeof RegistroDePlanesZod>

// Galeria
export const GaleriaVideoZod = z.object({
	galeriaVideoID: IDTextualZod,
	publico: BooleanOFalsoZod,
	nombre: z.string(),
	descripcion: z.string().optional().catch(undefined)
})
export type GaleriaVideo = z.infer<typeof GaleriaVideoZod>

// Video
const TipoVideoZod = z.enum(['vimeo', 'youtube']).optional()

export const VideoZod = z.object({
	bmID: IDNumericoZod,
	videoID: IDTextualZod,
	creacion: FechaZod,
	// actualizacion: FechaZod,

	publico: BooleanOFalsoZod,
	nombre: StringZod,
	descripcion: StringOpcionalZod,
	imagen: URLOpcionalZod,
	miniatura: URLOpcionalZod,

	visualizaciones: NumeroOpcionalZod,
	descuentaCupo: BooleanOVerdaderoZod,
	asociarProgramas: BooleanOFalsoZod,

	galeriasIDs: z.array(IDTextualZod),
	programasIDs: z.array(IDTextualZod),

	duracionEnSegundos: NumeroOpcionalZod,
	altura: NumeroOpcionalZod,
	anchura: NumeroOpcionalZod,

	tipoVideo: TipoVideoZod,
	iframeURL: URLOpcionalZod
})
export type Video = z.infer<typeof VideoZod>

const VideoCanjeZod = z.object({
	id: IDTextualZod,
	fechaDelCanje: FechaZod,
	fechaDeVencimiento: FechaZod,
	cliente: StringOpcionalZod,
	os: StringOpcionalZod,
	device: StringOpcionalZod,
	brand: StringOpcionalZod,
	model: StringOpcionalZod
})
export type VideoCanje = z.infer<typeof VideoCanjeZod>

const RegistroDeCanjesZod = z.record(z.string(), VideoCanjeZod)
export type RegistroDeCanjes = z.infer<typeof RegistroDeCanjesZod>

const VideoCanjeadoZod = VideoZod.pick({
	bmID: true,
	videoID: true,
	creacion: true,
	// actualizacion: true,

	// * Esto no se usa desde 5.20.12
	// publico: true,
	nombre: true,
	// descripcion: true,
	// imagen: true,
	// // miniatura: true,

	// visualizaciones: true,
	// descuentaCupo: true,
	// asociarProgramas: true,

	galeriasIDs: true,
	programasIDs: true,

	// duracionEnSegundos: true,
	// altura: true,
	// anchura: true,

	tipoVideo: true
}).extend({
	iframeURL: URLOpcionalZod,
	canjes: RegistroDeCanjesZod,

	// Mitigacion de deprecacion
	nombre: StringZod,
	// nombre: StringZod.optional(),
	galeriasIDs: z.array(IDTextualZod),
	// galeriasIDs: z.array(IDTextualZod).optional(),
	programasIDs: z.array(IDTextualZod)
	// programasIDs: z.array(IDTextualZod).optional(),
})
export type VideoCanjeado = z.infer<typeof VideoCanjeadoZod>

// Horario
export const HorarioSesionZod = z.object({
	horarioID: IDTextualZod,
	bmID: IDNumericoZod,
	activo: BooleanOFalsoZod,
	color: StringOpcionalZod,
	cupos: NumeroOCeroZod,
	horaInicio: HoraZod,
	horaFin: HoraZod,
	diaSemana: DiaSemanaZod,

	online: BooleanOFalsoZod,
	aceptaPrueba: BooleanOFalsoZod,

	salaID: IDTextualOpcionalZod,

	profesorIDs: z.array(IDTextualZod).catch([])
})
export type HorarioSesion = z.infer<typeof HorarioSesionZod>

// Clase
export const SesionZod = z.object({
	sesionID: IDTextualZod,
	claseID: IDTextualZod,
	bmID: IDNumericoZod,

	activa: BooleanOFalsoZod,

	nombre: StringZod,
	descripcion: StringOpcionalZod,
	color: StringOpcionalZod,

	valor: NumeroOpcionalZod,
	precio: PrecioOpcionalZod,

	programasIDs: z.array(IDTextualZod),
	programasBmIDs: z.array(IDNumericoOpcionalZod),
	horarios: z.record(z.string(), HorarioSesionZod),

	ultimaImportacion: FechaZod
})
export type Sesion = z.infer<typeof SesionZod>

export const RegistroDeSesionesZod = z.record(StringZod, SesionZod)
export type RegistroDeSesiones = z.infer<typeof RegistroDeSesionesZod>

// Programa
export const ProgramaZod = z.object({
	programaID: IDTextualZod,
	bmID: IDNumericoZod,
	activo: z.boolean(),
	nombre: z.string(),
	sedeID: NumeroOpcionalZod
})
export type Programa = z.infer<typeof ProgramaZod>

export const RegistroDeProgramasZod = z.record(z.string(), ProgramaZod)
export type RegistroDeProgramas = z.infer<typeof RegistroDeProgramasZod>

export const ProgramaConRelacionadosZod = ProgramaZod.extend({
	clases: z.lazy(() => RegistroDeSesionesZod),
	planes: z.lazy(() => RegistroDePlanesZod)
})
export type ProgramaConRelacionados = z.infer<typeof ProgramaConRelacionadosZod>

export const RegistroDeProgramasConRelacionadosZod = z.record(
	z.string(),
	ProgramaConRelacionadosZod
)
export type RegistroDeProgramasConRelacionados = z.infer<
	typeof RegistroDeProgramasConRelacionadosZod
>

// Plan con relacionados

export const PlanConRelacionadosZod = PlanZod.extend({
	programas: z.lazy(() => RegistroDeProgramasConRelacionadosZod),
	clases: z.lazy(() => RegistroDeSesionesZod)
})
export type PlanConRelacionados = z.infer<typeof PlanConRelacionadosZod>

export const RegistroDePlanesConRelacionadosZod = z.record(
	z.string(),
	PlanConRelacionadosZod
)
export type RegistroDePlanesConRelacionados = z.infer<
	typeof RegistroDePlanesConRelacionadosZod
>

export const PagoMembresiaZod = z.object({
	pagoID: IDTextualZod,
	bmID: IDNumericoZod,
	fechaDelPago: FechaZod,

	inicioVigencia: FechaZod,
	finVigencia: FechaZod,

	monto: NumeroZod,
	boleta: BooleanOFalsoZod,
	observacion: StringOpcionalZod,
	medioDePago: MedioDePagoZod,

	cuposDescontados: NumeroOCeroZod,
	cuposAgregados: NumeroOCeroZod,

	congelado: BooleanOFalsoZod,
	congeladoFecha: FechaOpcionalZod,
	eliminacion: FechaOpcionalZod,

	periodosDeCupos: z.record(z.string(), PeriodoDeVigenciaDeCuposZod),
	reservas: z.record(z.string(), ReservaEnPagoZod).catch({}),
	stats: StatsDeReservasZod
})
export type PagoMembresia = z.infer<typeof PagoMembresiaZod>

export const MembresiaZod = z.object({
	membresiaID: IDTextualZod,
	bmID: IDNumericoZod,

	planID: IDTextualZod,
	planNombre: StringZod,
	activa: BooleanOFalsoZod,

	diaDePago: z.number().min(1).max(31).catch(1),
	observacion: StringOpcionalZod,
	sobrenombre: StringOpcionalZod,
	esDePerfil: BooleanOFalsoZod,

	inicioVigencia: FechaOpcionalZod,
	finVigencia: FechaOpcionalZod,

	urlRenovacion: StringOpcionalZod,

	pagos: z.record(z.string(), PagoMembresiaZod).catch({})
})
export type Membresia = z.infer<typeof MembresiaZod>

export const RegistroDeMembresiasZod = z.record(z.string(), MembresiaZod)
export type RegistroDeMembresias = z.infer<typeof RegistroDeMembresiasZod>

export const MembresiaConRelacionadosZod = MembresiaZod.extend({
	plan: z.lazy(() => PlanConRelacionadosZod),
	programas: z.lazy(() => RegistroDeProgramasZod),
	clases: z.lazy(() => RegistroDeSesionesZod)
})
export type MembresiaConRelacionados = z.infer<
	typeof MembresiaConRelacionadosZod
>

const UsuarioEstadoZod = z.enum(['activo', 'congelado', 'suspendido', 'prueba', 'inactivo', 'nuevo']).optional().catch(undefined)
export type EstadoPerfilEnGimnasio = z.infer<typeof UsuarioEstadoZod>

export const EventoZod = z.object({
	id: IDTextualZod,
	eventoID: IDTextualZod,
	bmID: IDNumericoZod,
	gimnasioID: IDTextualZod,
	timezone: StringZod,

	nombre: StringZod,
	imagen: URLOpcionalZod,
	descripcion: StringOpcionalZod,
	color: StringOpcionalZod,

	maximoDeParticipantes: NumeroOpcionalZod,
	hayDisponibles: BooleanOFalsoZod,
	cuposDisponibles: NumeroOpcionalZod,

	fecha: FechaZod,
	horaInicio: HoraZod,
	horaFin: HoraZod,
	fechaInicio: FechaZod,
	fechaFin: FechaZod,

	descuentaCupo: BooleanOVerdaderoZod,
	publico: BooleanOFalsoZod,
	venderOnline: BooleanOFalsoZod,
	precio: PrecioOpcionalZod,

	direccion: StringOpcionalZod,
	latitud: NumeroOpcionalZod,
	longitud: NumeroOpcionalZod,

	esOnline: BooleanOFalsoZod,
	urlIngreso: URLOpcionalZod,

	programaID: IDTextualOpcionalZod,

	tipoStreaming: StringOpcionalZod,
	asistencia_visible: BooleanOFalsoZod,
	videoURL: URLOpcionalZod
})
export type Evento = z.infer<typeof EventoZod>

const EventoEnQueParticipaZod = z.object({
	eventoID: IDTextualZod,
	fecha: FechaZod
})

export const EventosEnQueParticipaZod = z.record(z.string(), EventoEnQueParticipaZod)

export const UsuarioVinculadoZod = z.object({
	usuarioID: IDTextualZod,
	nombre: StringZod,
	apellido: StringZod,
	avatar: URLOpcionalZod,
	rol: StringZod
})

export const ParticipacionEnEventoZod = z.object({
	// eventoID: IDTextualZod,
	asistio: BooleanOFalsoZod,
	confirmado: BooleanOFalsoZod,
	confirmadoPor: UsuarioVinculadoZod.optional(),
	confirmadoEl: FechaOpcionalZod
})

// * Rutinas

export const RutinaCategoriaZod = z.object({
	categoriaID: IDTextualZod,
	// bmID: IDNumericoZod,
	nombre: StringZod,
	nivel: NumeroZod
})
export type RutinaCategoria = z.infer<typeof RutinaCategoriaZod>
export const RegistroDeRutinaCategoriasZod = z.record(z.string(), RutinaCategoriaZod)
export type RegistroDeRutinaCategorias = z.infer<typeof RegistroDeRutinaCategoriasZod>
export const RutinaTipoPuntajeZod = z.enum(['peso', 'tiempo', 'rondas', 'repeticiones', 'rondas+repeticiones'])
export const RutinaSeccionZod = z.object({
	seccionID: IDTextualZod,
	// bmID: IDNumericoZod,
	orden: NumeroZod,

	titulo: StringOpcionalZod,
	nombre: StringOpcionalZod,
	texto: StringOpcionalZod,

	tipoActivo: BooleanOFalsoZod,
	tipoPuntaje: RutinaTipoPuntajeZod,

	// videoLink: StringOpcionalZod,
	// videoTipo: TipoVideoZod.optional(),
	videoIframeURL: URLOpcionalZod,
	videoImagenURL: URLOpcionalZod
})

const TipoRutinaEnumZod = z.enum(['por_cliente', 'por_programa']).optional()
const TipoRutinaZod = z.preprocess((val: unknown) => {
	if (val === 'por_cliente' || val === 'por_programa')
		return val
	else
		return undefined
}, TipoRutinaEnumZod)

const RutinaIDZod = IDTextualZod
const UsuarioEnPuntajeZod = z.object({
	nombre: StringOpcionalZod,
	apellido: StringOpcionalZod,
	avatarURL: URLOpcionalZod
})

const PuntajeZod = z.object({
	// puntajeBMID: IDNumericoZod,
	// bmID: IDNumericoZod,
	// categoriaBmID: IDNumericoZod,

	puntajeID: IDTextualZod,
	rutinaID: IDTextualZod,
	usuarioID: IDTextualZod,
	usuario: UsuarioEnPuntajeZod.optional().nullable(),

	categoriaID: IDTextualZod,
	puntaje: StringOpcionalZod,
	orden: NumeroZod
})
export type Puntaje = z.infer<typeof PuntajeZod>
const PuntajeIDZod = StringZod
const PuntajesEnRutinaZod = z.record(PuntajeIDZod, PuntajeZod)
export type PuntajesEnRutina = z.infer<typeof PuntajesEnRutinaZod>
export const RutinaZod = z.object({
	// bmID: IDNumericoZod,
	rutinaID: RutinaIDZod,

	creadorID: IDTextualZod,
	// creadorBMID: IDNumericoZod,

	claseID: IDTextualOpcionalZod,
	// claseBMID: IDNumericoOpcionalZod,

	nombre: StringOpcionalZod,
	fecha: FechaZod,
	fechaYMD: FechaYMDZod,

	creada: FechaZod,
	actualizada: FechaZod,

	visible: BooleanOFalsoZod,
	tipoPuntaje: RutinaTipoPuntajeZod,
	spotifyPlaylistID: StringOpcionalZod,

	secciones: z.record(z.string(), RutinaSeccionZod),

	esOnline: BooleanOFalsoZod,
	tipoStreaming: StringOpcionalZod,
	urlAcceso: URLOpcionalZod,

	tipoRutina: TipoRutinaZod,
	// programaBMIDs: z.array(IDNumericoZod),
	programaIDs: z.array(IDTextualZod),
	// usuarioBMIDs: z.array(IDNumericoZod),
	usuarioIDs: z.array(IDTextualZod),

	// primeraImportacion: FechaZod,
	// ultimaImportacion: FechaZod,

	puntajes: PuntajesEnRutinaZod
})

export type Rutina = z.infer<typeof RutinaZod>

// export const RegistroDeRutinasZod = z.record(RutinaZod)
// export type RegistroDeRutinas = z.infer<typeof RegistroDeRutinasZod>

export const ConfiguracionDeNotificacionesZod = z.object({
	recordatoriosDeSesiones: BooleanOpcionalZod,
	recordatoriosDeSesionesMinutosAntes: NumeroOpcionalZod.default(15),
	recordarVencimientoDePlan: BooleanOpcionalZod,
	pushListaDeEspera: BooleanOpcionalZod,
	push: BooleanOpcionalZod,
})
export type ConfiguracionDeNotificaciones = z.infer<typeof ConfiguracionDeNotificacionesZod>

export const ConfiguracionDePerfilEnGimnasioZod = z.object({
	notificaciones: ConfiguracionDeNotificacionesZod.optional()
})
export type ConfiguracionDePerfilEnGimnasio = z.infer<typeof ConfiguracionDePerfilEnGimnasioZod>	


//* Perfil en gimnasio

export const PerfilEnGimnasioZod = z.object({
	perfilEnGimnasioID: IDTextualZod,
	gimnasioID: IDTextualZod,
	usuarioID: IDTextualZod,

	estado: UsuarioEstadoZod,
	roles: z.array(RolZod),
	permisos: PermisosZod,

	contrato: StringOpcionalZod,
	contratoAceptado: z.boolean().optional(),

	autorizado: z.boolean().nullable().optional(),
	urlAutorizacion: URLOpcionalZod,

	membresias: z.record(z.string(), MembresiaZod).optional(),
	reservas: z.record(z.string(), ReservaZod).optional(),
	reservasNoAsignadas: z.record(z.string(), ReservaZod).optional(),
	rutinas: z.record(z.string(), RutinaZod).optional(),

	configuraciones: ConfiguracionDePerfilEnGimnasioZod.optional(),

	videosCanjeados: z.record(z.string(), VideoCanjeadoZod).optional(),

	eventosEnQueParticipa: EventosEnQueParticipaZod.optional(),
	
	marcaDeTiempo: StringOpcionalZod
})
export type PerfilEnGimnasio = z.infer<typeof PerfilEnGimnasioZod>

export const RegistroDePerfilesEnGimnasioZod = z.record(
	z.string(),
	PerfilEnGimnasioZod
)
export type RegistroDePerfilesEnGimnasio = z.infer<typeof RegistroDePerfilesEnGimnasioZod>

export const PerfilEnGimnasioConRelacionadosZod = PerfilEnGimnasioZod.extend({
	membresiasConRelacionados: z.record(z.string(), MembresiaConRelacionadosZod),
	reservasConRelacionados: z.record(z.string(), ReservaZod)
})

export type PerfilEnGimnasioConRelacionados = z.infer<
	typeof PerfilEnGimnasioConRelacionadosZod
>

const RegistroDePerfilesEnGimnasioConRelacionadosZod = z.record(
	z.string(),
	PerfilEnGimnasioConRelacionadosZod
)
export type RegistroDePerfilesEnGimnasioConRelacionados = z.infer<
	typeof RegistroDePerfilesEnGimnasioConRelacionadosZod
>

export const AutenticacionZod = z.object({
	tokenID: z.string(),
	ultimoUso: FechaZod,
	creacion: FechaZod,
	expiracion: FechaZod,
	revocada: FechaOpcionalZod,

	ultimaSuscripcionPush: FechaOpcionalZod,
})
export type Autenticacion = z.infer<typeof AutenticacionZod>

export const AutenticacionesZod = z.record(z.string(), AutenticacionZod)
export type Autenticaciones = z.infer<typeof AutenticacionesZod>

export const UsuarioZod = z.object({
	usuarioID: IDTextualZod,
	bmID: IDNumericoZod,

	estado: UsuarioEstadoZod,
	email: EmailZod,
	perfil: PerfilZod,
	roles: z.array(RolZod),

	gimnasioIDs: z.array(IDTextualZod),

	preferencias: PreferenciasPerfilZod.optional(),
	permisos: PermisosZod,

	autenticaciones: AutenticacionesZod.optional(),

	marcaDeTiempo: StringOpcionalZod
})
export type Usuario = z.infer<typeof UsuarioZod>

export const MiniusuarioZod = z.object({
	usuarioID: IDTextualZod,
	avatar: URLOpcionalZod,
	nombre: StringOpcionalZod,
	apellido: StringOpcionalZod,
	email: StringOpcionalZod,
	estado: UsuarioEstadoZod
})
export type Miniusuario = z.infer<typeof MiniusuarioZod>

export const NotificableZod = z.object({
	miniusuario: MiniusuarioZod,
	autenticaciones: AutenticacionesZod
})
export type Notificable = z.infer<typeof NotificableZod>
export const ListaNotificablesZod = z.record(z.string(), NotificableZod)

export type ListaNotificables = z.infer<typeof ListaNotificablesZod>

export const ParticipanteZod = z.object({
	miniusuario: MiniusuarioZod,
	perfilEnGimnasio: PerfilEnGimnasioZod.optional()
})
export type Participante = z.infer<typeof ParticipanteZod>

export const ListaDeParticipantesZod = z.record(z.string(), ParticipanteZod)
export type ListaDeParticipantes = z.infer<typeof ListaDeParticipantesZod>

const ParticipanteConRelacionadosZod = ParticipanteZod.extend({
	perfilEnGimnasio: PerfilEnGimnasioConRelacionadosZod
})
export type ParticipanteConRelacionados = z.infer<
	typeof ParticipanteConRelacionadosZod
>

export type ParticipanteConRelacionadosYPosibilidadesDeReserva = ParticipanteConRelacionados & {
	posibilidadesDeReserva: PosibilidadDeReserva[]
}

export const RegistroIMCZod = z.object({
	fecha: FechaYMDZod,
	imc: NumeroZod,
	peso: NumeroZod
})
export const RegistroGrasaZod = z.object({
	fecha: FechaYMDZod,
	grasa: NumeroZod
})
export const RegistroPautaZod = z.object({
	fecha: FechaYMDZod,
	nombre: StringZod,
	url: URLZod
})

export type RegistroIMC = z.infer<typeof RegistroIMCZod>
export type RegistroGrasa = z.infer<typeof RegistroGrasaZod>
export type RegistroPauta = z.infer<typeof RegistroPautaZod>

export const EvaluacionesZod = z.object({
	imc: z.array(RegistroIMCZod),
	grasa: z.array(RegistroGrasaZod),
	pautas: z.array(RegistroPautaZod)
})

export type Evaluaciones = z.infer<typeof EvaluacionesZod>

// Noticias

export const BorradorNoticiaZod = z.object({
	titulo: StringZod,
	contenido: StringZod,

	imagen: StringZod,
	etiquetas: z.array(StringZod),

	programaIDs: z.array(IDTextualZod)
})
export type BorradorNoticia = z.infer<typeof BorradorNoticiaZod>

const EstadoNoticiaZod = z.union([
	z.literal('borrador'),
	z.literal('publicada'),
	z.literal('archivada'),
	z.literal('eliminada')
])

// const TipoModificacionZod = z.union([
// 	z.literal('creacion'),
// 	z.literal('edicion'),
// 	z.literal('publicacion'),
// 	z.literal('archivacion'),
// 	z.literal('eliminacion')
// ])

// const ModificacionZod = z.object({
// 	usuarioID: IDTextualZod,
// 	fecha: FechaZod,
// 	tipo: TipoModificacionZod,
// 	alteracion: z.any()
// })

export const NoticiaZod = z.object({
	// _id: IDTextualZod,
	noticiaID: IDTextualZod,

	creacion: FechaZod,
	actualizacion: FechaZod,
	fechaPublicacion: FechaOpcionalZod,
	fechaArchivacion: FechaOpcionalZod,

	estado: EstadoNoticiaZod,
	titulo: StringZod,
	contenido: StringZod,
	resumen: z.string().max(140).catch(''),

	imagen: URLOpcionalZod,
	etiquetas: z.array(StringZod),
	programaIDs: z.array(IDTextualZod)

	// modificaciones: z.record(ModificacionZod).optional(),
})
export type Noticia = z.infer<typeof NoticiaZod>

export const EdicionDeNoticiaZod = z.object({
	fechaPublicacion: FechaOpcionalZod,
	fechaArchivacion: FechaOpcionalZod.nullable(),

	estado: EstadoNoticiaZod,

	titulo: StringZod,
	contenido: StringZod,
	resumen: z.string().max(140),

	imagen: z.string().url().nullable(),
	etiquetas: z.array(StringZod),

	programaIDs: z.array(IDTextualZod)
}).partial()

export type EdicionDeNoticia = z.infer<typeof EdicionDeNoticiaZod>

export const RegistroDeNoticiasZod = z.record(NoticiaZod)
export type RegistroDeNoticias = z.infer<typeof RegistroDeNoticiasZod>

// Producto
export const ProductoZod = z.object({
	productoID: IDTextualZod,

	creacion: FechaZod,
	actualizacion: FechaZod,

	mostrarEnTienda: BooleanOFalsoZod,

	nombre: z.string(),
	descripcion: StringOpcionalZod,
	imagen: StringOpcionalZod,

	precio: PrecioOpcionalZod,
	precioAnterior: PrecioOpcionalZod,

	url: URLOpcionalZod
})
export type Producto = z.infer<typeof ProductoZod>

//* Anuncios

export const AnuncioZod = z.object({
	anuncioID: IDTextualZod,
	texto: z.string(),
	usuarioID: IDTextualOpcionalZod,
	fechaDesde: FechaZod,
	fechaHasta: FechaZod
})
export type Anuncio = z.infer<typeof AnuncioZod>

// Salas

export const ElementoEnColumnaZod = z.object({
	id: IDTextualZod,
	orden: NumeroZod,

	nombre: StringOpcionalZod,
	estado: StringOpcionalZod
})
export type ElementoEnColumna = z.infer<typeof ElementoEnColumnaZod>

export const ColumnaEnSalaZod = z.object({
	id: IDTextualZod,
	orden: NumeroZod,

	elementos: z.record(z.string(), ElementoEnColumnaZod)
})
export type ColumnaEnSala = z.infer<typeof ColumnaEnSalaZod>

export const SalaZod = z.object({
	bmID: IDNumericoZod,
	salaID: IDTextualZod,

	nombre: StringOpcionalZod,
	columnas: z.record(z.string(), ColumnaEnSalaZod),
	capacidad: NumeroZod,
	// capacidad: NumeroOpcionalZod,

	// primeraImportacion: FechaZod,
	ultimaImportacion: FechaOpcionalZod
})
export type Sala = z.infer<typeof SalaZod>

// Perfil del centro

export const PerfilDelCentroZod = z.object({
	nombre: z.string().default('Centro sin nombre'),
	logo: StringOpcionalZod,
	slogan: StringOpcionalZod,
	descripcion: StringOpcionalZod,
	codigo: StringOpcionalZod,

	timezone: z.string(),
	idioma: IdiomasDisponiblesZod.optional().catch(undefined),

	status: z.string(),
	slug: StringOpcionalZod,
	hashID: z.string(),

	ubicacion: z.object({
		direccion: StringOpcionalZod,
		lat: NumeroOpcionalZod,
		lon: NumeroOpcionalZod,
		comuna: StringOpcionalZod,
		region: StringOpcionalZod,
		pais: StringOpcionalZod,
		codigoPostal: StringOpcionalZod
	}),

	contacto: z.object({
		email: StringOpcionalZod,
		telefono: StringOpcionalZod,
		sitioWeb: StringOpcionalZod
	}),

	perfilesSociales: z.object({
		facebook: z.preprocess((v: unknown) => v ?? undefined, StringOpcionalZod),
		instagram: z.preprocess((v: unknown) => v ?? undefined, StringOpcionalZod),
		spotify: z.preprocess((v: unknown) => v ?? undefined, StringOpcionalZod),
		tiktok: z.preprocess((v: unknown) => v ?? undefined, StringOpcionalZod)
	})
})

export const GimnasioCaracteristicasZod = z.object({
	clases: BooleanOFalsoZod,
	canchas: BooleanOFalsoZod,
	rutinas: BooleanOFalsoZod,
	planes: BooleanOFalsoZod,
	tienda: BooleanOFalsoZod,
	tiendaDePlanes: BooleanOFalsoZod,
	tiendaDeProductos: BooleanOFalsoZod,
	videos: BooleanOFalsoZod,
	eventos: BooleanOFalsoZod,

	formularioClase: BooleanOFalsoZod,
	evaluacionPostClase: BooleanOFalsoZod,
	reusOnline: BooleanOFalsoZod,

	personalRecords: BooleanOFalsoZod,
	mostrarPersonalRecords: BooleanOFalsoZod,
	benchmarks: BooleanOFalsoZod,
	mostrarBenchmarks: BooleanOFalsoZod,
	evaluaciones: BooleanOFalsoZod,

	sugerirPlanesParaClase: BooleanOFalsoZod,

	mostrarParticipantes: BooleanOFalsoZod,
	verificarAsistencia: BooleanOFalsoZod,
	asistenciaEscaneable: BooleanOFalsoZod,

	mostrarEnBuscador: BooleanOFalsoZod,

	mostrarContrato: BooleanOFalsoZod,
	permitirAceptacionContrato: BooleanOFalsoZod,
	requerirAceptacionContrato: BooleanOFalsoZod,

	postCovid: BooleanOFalsoZod,
	chatbot: StringOpcionalZod,
	intercomAppID: StringOpcionalZod,

	googleAnalytics: StringOpcionalZod,
	aceptaPagosFuturos: BooleanOFalsoZod,

	requiereAutorizacion: BooleanOFalsoZod.optional(),

	mostrarProgramasEnPlanes: BooleanOFalsoZod,

	noticias: BooleanOFalsoZod,
	planesFiltrables: BooleanOFalsoZod,
	programas: BooleanOFalsoZod,

	programasEnTarjetaInstancia: BooleanOFalsoZod,
	programasEnModalInstancia: BooleanOFalsoZod,

	diasConsideradosRecientes: NumeroOpcionalZod,
	mostrarHorariosEnFeriados: BooleanOFalsoZod,

	traduccionesPersonalizadas: BooleanOFalsoZod.optional()
})
export type GimnasioCaracteristicas = z.infer<typeof GimnasioCaracteristicasZod>

const TraduccionPersonalizada = z.object({
	es: StringZod,
	en: StringZod,
	pt: StringZod
})

export const TraduccionesPersonalizadasZod = z.object({
	app: z.object({
		horarios: TraduccionPersonalizada,
		tienda: TraduccionPersonalizada
	})
})

export const ContratoZod = z.object({
	creacion: FechaOpcionalZod,
	actualizacion: FechaOpcionalZod,

	contratoID: IDTextualZod,

	servicio: StringOpcionalZod,
	clausulas: StringOpcionalZod,
	representanteNombre: StringOpcionalZod,
	representanteRut: StringOpcionalZod,

	obligatorio: BooleanOFalsoZod
})

export const VirtualPosZod = z.object({
	activo: BooleanOFalsoZod,
	oneClickClientes: BooleanOFalsoZod
})

export const PasarelasDePagoZod = z.object({
	virtualPos: VirtualPosZod
})

export const ConfiguracionesGimnasioZod = z.object({

	// ** Lista de espera
	ListaDeEsperaHabilitada: BooleanOFalsoZod,
	// * Minutos en torno a la hora de inicio de la clase. Se utilizan para determinar en qué momento expira la lista de espera.
	ListaDeEsperaDiferenciaExpiracionMinutos: NumeroOpcionalZod,
	MostrarListaDeEsperaAunSiHayCuposDisponibles: BooleanOFalsoZod,
	// * Segundos de ventaja otorgados a cada registro en la lista de espera.
	segundosDeVentajaPorEspera: NumeroOpcionalZod,


	// ** Rutinas
	RutinasHabilitadas: BooleanOFalsoZod,

	// ** Acciones de profe
	// permitirQuitarParticipantePosteriorAFin: NumeroOCeroZod,
	QuitarParticipanteMinutosPosteriorAFin: NumeroOCeroZod,

	// permitirMarcarAsistenciaPrevioAInicio: NumeroOpcionalZod,
	MarcarAsistenciaMinutosPrevioAInicio: z.number().catch(15),
	// permitirMarcarAsistenciaPosteriorAFin: NumeroOpcionalZod
	MarcarAsistenciaMinutosPosteriorAFin: z.number().catch(60 * 24 * 3),

	// ** Reservas
	diasDePlazoParaOpinar: NumeroZod.catch(7),

	// ** Memebresías
	mostrarTodasLasMembresias: BooleanOVerdaderoZod,

	// ** Menu
	logoDeAppEsProtagonista: BooleanOFalsoZod,

	usuariosPuedenReservar: BooleanOVerdaderoZod,
	
	sesionesMultiagendables: BooleanOFalsoZod,

	notificaciones: ConfiguracionDeNotificacionesZod.optional()
})

// * Gimnasio
export const GimnasioZod = z.object({
	bmID: IDNumericoZod,
	gimnasioID: IDTextualZod,
	activo: BooleanOFalsoZod,

	perfilDelCentro: PerfilDelCentroZod,
	tiposActividades: z.record(z.string()),
	caracteristicas: GimnasioCaracteristicasZod,

	mensajes: z.object({
		bienvenida: StringOpcionalZod,
		clase: z.object({
			antes: StringOpcionalZod,
			despues: StringOpcionalZod
		})
	}),
	restricciones: z.object({
		reservaAperturaMins: NumeroOpcionalZod,
		reservaAntelacionMins: NumeroOpcionalZod,
		reservaTrasInicioMins: NumeroOpcionalZod,
		limiteDiasFuturosReserva: NumeroOpcionalZod,
		cancelacionTiempoMinimoMins: NumeroOpcionalZod,
		aceptaPostpago: BooleanOFalsoZod,
		limitarReservasDiariasPorMembresia: BooleanOFalsoZod,
		rutinasVisiblesPorReserva: BooleanOFalsoZod,
	}),
	configuraciones: ConfiguracionesGimnasioZod,

	programas: z.record(z.string(), ProgramaZod),
	planes: z.record(z.string(), PlanZod),
	productos: z.record(z.string(), ProductoZod),
	clases: z.record(z.string(), SesionZod),

	// administradores: z.record(z.string(), MiniusuarioZod),
	profesores: z.record(z.string(), MiniusuarioZod),

	feriados: z.record(z.string(), z.array(z.string())),
	eventos: z.record(z.string(), EventoZod),
	anuncios: z.record(z.string(), AnuncioZod),
	galeriasVideo: z.record(z.string(), GaleriaVideoZod),
	videos: z.record(z.string(), VideoZod),
	salas: z.record(z.string(), SalaZod),
	traduccionesPersonalizadas: TraduccionesPersonalizadasZod.optional(),
	rutinaCategorias: z.record(z.string(), RutinaCategoriaZod).optional(),
	noticias: z.record(z.string(), NoticiaZod).optional(),
	contrato: ContratoZod.optional().catch(undefined),
	pasarelasDePago: PasarelasDePagoZod,

	marcaDeTiempo: StringOpcionalZod
})
export type Gimnasio = z.infer<typeof GimnasioZod>

export const RegistroDeGimnasiosZod = z.record(z.string(), GimnasioZod)
export type RegistroDeGimnasios = z.infer<typeof RegistroDeGimnasiosZod>

export type ExtractoGimnasio = Pick<Gimnasio, 'planes' | 'programas' | 'clases'>

export const IndicadorZod = z.union([
	z.literal('noInscrito'),
	z.literal('inscrito'),
	z.literal('conMembresia'),
	z.literal('conMembresiaVigente')
])
export type Indicador = z.infer<typeof IndicadorZod>

export const GimnasioListadoZod = GimnasioZod.pick({
	// _id: true,
	bmID: true,
	activo: true,
	gimnasioID: true,
	// perfilDelCentro: true,

	tiposActividades: true, // Se omite en v5.20+
	caracteristicas: true, // Se omite en v5.20+

	// clases: true, // Se omite en v5.20+
	// programas: true,
	planes: true // Se omite en v5.20+
}).extend({
	perfilDelCentro: PerfilDelCentroZod.pick({
		nombre: true,
		logo: true,
		descripcion: true,

		idioma: true,
		timezone: true,

		status: true,
		slug: true,

		ubicacion: true
	}),

	caracteristicas: GimnasioCaracteristicasZod.optional(),
	tiposActividades: z.record(z.string()).optional(),
	planes: z.record(z.string(), PlanZod).optional()
})

export type GimnasioListado = z.infer<typeof GimnasioListadoZod>

export const RegistroDeGimnasiosListadosZod = z.record(z.string(), GimnasioListadoZod)
export type RegistroDeGimnasiosListados = z.infer<typeof RegistroDeGimnasiosListadosZod>

export const ReservaEnInstanciaZod = ReservaZod
export type ReservaEnInstancia = z.infer<typeof ReservaEnInstanciaZod>

export const SalaConReservasZod = SalaZod.extend({
	columnas: z.record(
		z.string(),
		ColumnaEnSalaZod.extend({
			elementos: z.record(
				z.string(),
				ElementoEnColumnaZod.extend({
					reservaID: IDTextualOpcionalZod
				})
			)
		})
	)
})
export type SalaConReservas = z.infer<typeof SalaConReservasZod>

export const SolicitudDeReservaMiniZod = z.object({
	membresiaID: IDTextualZod,
	pagoID: IDTextualZod,
	lugarID: IDTextualOpcionalZod,

	aceptaListaDeEspera: BooleanOFalsoZod,
	aceptaCualquierLugar: BooleanOpcionalZod
})

export type SolicitudDeReservaMini = z.infer<typeof SolicitudDeReservaMiniZod>
export const SolicitudDeReservaZod = SolicitudDeReservaMiniZod.extend({
	solicitudID: IDTextualZod,
	creacionFecha: FechaZod,

	usuarioID: IDTextualZod,
	perfilEnGimnasioID: IDTextualZod,
	membresiaID: IDTextualZod,
	pagoID: IDTextualZod,
	lugarID: IDTextualOpcionalZod,

	// Resultado
	resultadoFecha: FechaOpcionalZod,
	resultado: z.enum(['agendada', 'rechazada', 'espera', 'esperaCancelada', 'esperaExpirada', 'agendadaConEspera']).optional(),
	resultadoCodigo: StringOpcionalZod,
	resultadoVerboso: StringOpcionalZod
})
export type SolicitudDeReserva = z.infer<typeof SolicitudDeReservaZod>

export const AutorizacionDeUsoDeCupoLiberadoZod = z.object({
	fecha: FechaZod,
	usuarioID: IDTextualZod,
	solicitudID: IDTextualZod,
	solicitudDeNotificacionID: IDTextualOpcionalZod
})

export const CupoLiberadoZod = z.object({
	liberacionFecha: FechaZod,
	autorizacionesDeUso: z.record(z.string(), AutorizacionDeUsoDeCupoLiberadoZod),
	utilizacionFecha: FechaOpcionalZod,
	autorizacionIDUtilizada: IDTextualOpcionalZod,
	listaDeEsperaCompletada: BooleanOFalsoZod
})

export type CupoLiberado = z.infer<typeof CupoLiberadoZod>

export const InstanciaZod = InstanciaCalculadaZod.extend({
	hash: StringOpcionalZod, // Sólo para versiones anteriores a la 5.26

	reservas: z.record(z.string(), ReservaEnInstanciaZod),
	lugares: z.record(z.string(), z.string().nullable().catch(null)).optional().catch(undefined),

	cuposDisponibles: NumeroOCeroZod,
	espaciosDisponibles: NumeroOCeroZod,
	capacidad: NumeroZod,

	profesorIDs: z.array(IDTextualZod).catch([]),
	creacionFecha: FechaOpcionalZod,
	ultimoUpdate: FechaOpcionalZod,

	solicitudesDeReserva: z.record(z.string(), SolicitudDeReservaZod),
	cuposLiberados: z.record(z.string(), CupoLiberadoZod).optional(),
})

export type Instancia = z.infer<typeof InstanciaZod>

export const ReservabilidadZod = z.discriminatedUnion('reservable', [z.object({
	reservable: z.literal(true)
}), z.object({
	reservable: z.literal(false),
	motivo: z.string()
})])
export type Reservabilidad = z.infer<typeof ReservabilidadZod>

export const PosibilidadDeReservaZod = z.object({
	// * Identificadores
	posibilidadID: IDTextualZod,
	instanciaID: IDTextualZod,
	membresiaID: IDTextualZod,
	pagoID: IDTextualZod,
	fechaInicio: FechaZod,

	// * Uso
	reservaIDs: z.array(IDTextualZod),

	// * Vigencia y cupos
	periodoDeCupos: PeriodoDeVigenciaDeCuposZod,

	sabadoLibre: BooleanOFalsoZod,
	utilizaCupo: BooleanOVerdaderoZod,
	cuposIlimitados: BooleanOFalsoZod,
	cuposDisponibles: NumeroZod,

	// * Limites diarios
	maximoReservasDiarias: NumeroZod,
	cantidadDeReservasMismoDia: NumeroZod,
	cupoPorDia: BooleanOFalsoZod,

	reservasDelDiaPorPrograma: z.record(z.string(), NumeroZod),
	maximoDiarioAlcanzado: BooleanOFalsoZod,

	puedeReservarPorCupoDia: BooleanOFalsoZod,
	puedeReservarPorCupos: BooleanOFalsoZod,
	puedeReservarPorReservas: BooleanOFalsoZod,
	elegible: BooleanOFalsoZod
})
export type PosibilidadDeReserva = z.infer<typeof PosibilidadDeReservaZod>
