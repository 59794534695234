import _ from 'lodash'

import { z } from 'zod'
import RosettasATraductor from '@base/lib/rosetta'
import { ErrorAumentado } from '@base/lib/error'
import { InstanciaZod, ListaDeParticipantesZod } from '@comun/types'
import type {
	HorarioSesion,
	Instancia,
} from '@comun/types'
import { RevisarSiUsaSalaYEligeUnLugarValido } from '@comun/reservas'
import { ErrorNotificableZod, i18nIconos, i18nTapi } from '../lib/erroresNotificables'

const consoloRaiz = 'Lib TAPI Profe'
const consoloColor = 'color: MediumPurple'

const i18n = RosettasATraductor({
	realizado: {
		es: 'Realizado',
		en: 'Done',
		pt: 'Feito'
	},
	intentoFallido: {
		es: 'Intento fallido',
		en: 'Failed attempt',
		pt: 'Tentativa falhou'
	},
	hecho: {
		es: 'Hecho',
		en: 'Done',
		pt: 'Feito'
	},
	yaNoGuiasEstaSesion: {
		es: 'Ya no guías esta sesión',
		en: 'You are no longer guiding this session',
		pt: 'Você não está mais guiando esta sessão'
	},
	yaNoImpartesEstaClase: {
		es: 'Ya no impartes esta clase',
		en: 'You are no longer teaching this class',
		pt: 'Você não está mais ensinando esta aula'
	},
	figurasComoImpartidorDeLaSesion: {
		es: 'Figuras como impartidor de la sesión',
		en: 'You are now the teacher of this session',
		pt: 'Figuras como professor da sessão'
	},
	reservaEliminada: {
		es: 'Reserva eliminada',
		en: 'Reservation removed',
		pt: 'Reserva removida'
	},
	noSePudoEliminarReserva: {
		es: 'No se pudo eliminar la reserva',
		en: 'Could not remove the reservation',
		pt: 'Não foi possível remover a reserva'
	},
	// seQuitoParticipanteDeLaSesion: {
	// 	es: 'Se quitó el participante de la sesión',
	// 	en: 'Participant removed from the session',
	// 	pt: 'Participante removido da sessão'
	// },
	// noSeQuitoParticipanteDeLaSesion: {
	// 	es: 'No se quitó el participante de la sesión',
	// 	en: 'Could not remove the participant from the session',
	// 	pt: 'Não foi possível remover o participante da sessão'
	// },

	reservaAgregada: {
		es: 'Reserva agregada',
		en: 'Reservation added',
		pt: 'Reserva adicionada'
	},
	noSePudoAgregarReserva: {
		es: 'No se pudo agregar la reserva',
		en: 'Could not add the reservation',
		pt: 'Não foi possível adicionar a reserva'
	}
})

async function ImpartirClase(
	instancia: Instancia
): Promise<boolean> {
	const fx = 'ProfeAPI.ImpartirClase'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		const { claseID, fechaYMD, horarioID } = instancia
		const headers = HeadersConAuth()
		const r = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/profe/ImpartirClase`,
			method: 'post',
			headers,
			data: {
				claseID,
				horarioID,
				fechaYMD
			}
		})
			

		if (r.ok) {
			notificadorEnApp.exito({
				titulo: i18n('realizado'),
				texto: i18n('figurasComoImpartidorDeLaSesion')
			})
		}

		return r.ok
	}
	catch (e) {
		consolo.error('error', e)
		return false
	}
	finally {
		await InstanciasAPI.ActualizarInstancias([instancia])
		consolo.groupEnd()
	}
}

async function SoltarClase(
	instancia: Instancia
): Promise<boolean> {
	const fx = 'SoltarClase'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		const { claseID, horarioID, fechaYMD } = instancia
		const headers = HeadersConAuth()
		const r = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/profe/SoltarClase`,
			method: 'post',
			headers,
			data: {
				claseID,
				horarioID,
				fechaYMD
			}
		})
			

		if (r.ok) {
			notificadorEnApp.exito({
				titulo: i18n('realizado'),
				texto: i18n('yaNoImpartesEstaClase')
			})
		}
		return r.ok
	}
	catch (e) {
		consolo.error('error', e)
		return false
	}
	finally {
		await InstanciasAPI.ActualizarInstancias([instancia])
		consolo.groupEnd()
	}
}

const ObtenerURLAccesoASesionOk = z.object({
	ok: z.literal(true),
	url: z.string()
})
const ObtenerURLAccesoASesionError = z.object({
	ok: z.literal(false),
	error: ErrorNotificableZod
})
const ObtenerURLAccesoASesionParser = z.discriminatedUnion('ok', [
	ObtenerURLAccesoASesionOk,
	ObtenerURLAccesoASesionError
])

async function AccederASesionOnline(
	instancia: Instancia
): Promise<string> {
	const fx = 'AccederASesionOnline'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		const { claseID, fechaYMD, horarioID } = instancia
		const headers = HeadersConAuth()
		const r = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/profe/accederASesionOnline`,
			method: 'post',
			headers,
			data: {
				claseID,
				fechaYMD,
				horarioID
			}
		})
			

		const parseo = ObtenerURLAccesoASesionParser.safeParse(r)
		if (!parseo.success) {
			// Reportar
			throw new ErrorAumentado('malParseoRespuesta', {
				datos: {
					parser: 'ObtenerURLAccesoASesionParser'
				},
				error: parseo.error
			})
		}

		const datosRecibidos = parseo.data
		if (!datosRecibidos.ok) {
			const errorParseado = datosRecibidos.error

			notificadorEnApp.atencion({
				titulo: i18nTapi('falloElIngreso'),
				texto: i18nTapi(errorParseado),
				codigo: errorParseado,
				icono: i18nIconos[errorParseado]
			})
			throw new ErrorAumentado('resultadoNegativo', {
				error: datosRecibidos.error
			})
		}
		if (!datosRecibidos.url)
			throw new ErrorAumentado(`${fx}: no hay url`)
		return r.url
	}
	catch (e) {
		consolo.error(`${fx}: error`, e)
		throw new ErrorAumentado(`${fx}: error`, { error: e })
	}
	finally {
		consolo.groupEnd()
	}
}
//

const obteniendoParticipantesRef = ref(false)
const ultimoUpdateParticipantesRef = ref<Dayjs | null>(null)

async function ListarParticipantes(
	forzar = false
): Promise<void> {
	const fx = 'ProfeAPI.ListarParticipantes'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)

	if (unref(obteniendoParticipantesRef)) {
		consolo.warn(`${fx}: ya se está obteniendo`)
		return
	}
	try {
		obteniendoParticipantesRef.value = true
		const { gimnasio: gim } = useGimnasio()
		const gimnasio = unref(gim)
		if (!gimnasio)
			throw new ErrorAumentado('No hay gimnasio')

		if (!forzar && unref(participantes)) {
			const ultimoUpdate = unref(ultimoUpdateParticipantesRef)

			if (ultimoUpdate && ultimoUpdate.isAfter(dayjs().subtract(3, 'minute')))
				return
		}

		const headers = HeadersConAuth()
		const r = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/profe/participantes`,
			method: 'get',
			headers
		})
			

		const parserParticipantes = z.object({
			ok: z.boolean(),
			participantes: ListaDeParticipantesZod
		})

		const parseado = parserParticipantes.parse(r)

		UsuariosAPI.IntegrarParticipantes({ gimnasio, participantes: parseado.participantes })
	}
	catch (e) {
		consolo.error(`${fx}: error`, e)
		throw new ErrorAumentado(`${fx}: error`, { error: e })
	}
	finally {
		obteniendoParticipantesRef.value = false
		consolo.groupEnd()
	}
}

const refrescandoParticipantesRef = ref<string[]>([])
export const refrescandoParticipantes = computed(() => unref(refrescandoParticipantesRef))

async function ObtenerAlgunosParticipantes(
	participanteIDs: string[] // Son usuarioIDS
): Promise<void> {
	const fx = 'ProfeAPI.ObtenerAlgunosParticipantes'
	consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)

	try {
		refrescandoParticipantesRef.value.push(...participanteIDs)
		const { gimnasio: gim } = useGimnasio()
		const gimnasio = unref(gim)
		if (!gimnasio)
			throw new ErrorAumentado('No hay gimnasio')

		const headers = HeadersConAuth()
		const r = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/profe/participantes`,
			method: 'post',
			headers,
			data: {
				participanteIDs
			}
		})
			

		const parserParticipantes = z.object({
			ok: z.boolean(),
			participantes: ListaDeParticipantesZod
		})

		const { participantes } = parserParticipantes.parse(r)
		UsuariosAPI.IntegrarParticipantes({ gimnasio, participantes })
	}
	catch (e) {
		consolo.error(`${fx}: error`, e)
		throw new ErrorAumentado(`${fx}: error`, { error: e })
	}
	finally {
		refrescandoParticipantesRef.value = _.difference(
			refrescandoParticipantesRef.value,
			participanteIDs
		)
		// consolo.groupEnd()
	}
}

const refrescandoParticipantesBMIDsRef = ref<number[]>([])

export const refrescandoParticipantesBMIDs = computed(() => unref(refrescandoParticipantesBMIDsRef))

// async function ObtenerAlgunosParticipantesPorBMID(
// 	participanteBMIDs: number[] // Son usuarioBMIDS
// ): Promise<void> {
// 	const fx = 'ProfeAPI.ObtenerAlgunosParticipantesPorBMID'
// 	consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)

// 	try {
// 		refrescandoParticipantesBMIDsRef.value.push(...participanteBMIDs)
// 		const { gimnasio: gim } = useGimnasio()
// 		const gimnasio = unref(gim)
// 		if (!gimnasio)
// 			throw new ErrorAumentado('No hay gimnasio')

// 		const headers = HeadersConAuth()
// 		const r = await axiosWorker({
// 			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/profe/participantesxBMID`,
// 			method: 'post',
// 			headers,
// 			data: {
// 				participanteBMIDs
// 			}
// 		})
			

// 		const parserParticipantes = z.object({
// 			ok: z.boolean(),
// 			participantes: ListaDeParticipantesZod
// 		})

// 		const { participantes } = parserParticipantes.parse(r)
// 		UsuariosAPI.IntegrarParticipantes({ gimnasio, participantes })
// 	}
// 	catch (e) {
// 		consolo.error(`${fx}: error`, e)
// 		throw new ErrorAumentado(`${fx}: error`, { error: e })
// 	}
// 	finally {
// 		refrescandoParticipantesBMIDsRef.value = _.difference(
// 			unref(refrescandoParticipantesBMIDs),
// 			participanteBMIDs
// 		)
// 	}
// }

async function ObtenerParticipantePorBMID(
	participanteBMID: number
): Promise<string> {
	const fx = 'ProfeAPI.ObtenerParticipantePorBMID'
	consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)

	try {
		refrescandoParticipantesBMIDsRef.value.push(participanteBMID)
		const { gimnasio: gim } = useGimnasio()
		const gimnasio = unref(gim)
		if (!gimnasio)
			throw new ErrorAumentado('No hay gimnasio')

		const headers = HeadersConAuth()
		const r = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/profe/participantexBMID`,
			method: 'post',
			headers,
			data: {
				participanteBMID
			}
		})
			

		const parserParticipantes = z.object({
			ok: z.boolean(),
			participantes: ListaDeParticipantesZod,
			participanteID: z.string()
		})

		const { participantes, participanteID } = parserParticipantes.parse(r)
		UsuariosAPI.IntegrarParticipantes({ gimnasio, participantes })
		return participanteID
	}
	catch (e) {
		consolo.error(`${fx}: error`, e)
		throw new ErrorAumentado(`${fx}: error`, { error: e })
	}
	finally {
		refrescandoParticipantesBMIDsRef.value = _.difference(
			unref(refrescandoParticipantesBMIDs),
			[participanteBMID]
		)
	}
}

const busquedaEnProgresoRef = ref<string | null>(null)
async function BuscarParticipantes(
	busqueda: string
): Promise<void> {
	const fx = 'ProfeAPI.BuscarParticipantes'
	consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)

	try {
		busquedaEnProgresoRef.value = busqueda
		const { gimnasio: gim } = useGimnasio()
		const gimnasio = unref(gim)
		if (!gimnasio)
			throw new ErrorAumentado('No hay gimnasio')

		const headers = HeadersConAuth()
		const r = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/profe/buscarParticipantes`,
			method: 'post',
			headers,
			data: {
				busqueda
			}
		})
			

		const parserParticipantes = z.object({
			ok: z.boolean(),
			participantes: ListaDeParticipantesZod
		})

		const { participantes } = parserParticipantes.parse(r)
		UsuariosAPI.IntegrarParticipantes({ gimnasio, participantes })
	}
	catch (e) {
		consolo.error(`${fx}: error`, e)
		throw new ErrorAumentado(`${fx}: error`, { error: e })
	}
	finally {
		busquedaEnProgresoRef.value = null
	}
}

async function AgregarParticipanteAInstancia(datos: {
	instancia: Instancia
	horario: HorarioSesion

	participanteID: string
	membresiaID: string
	pagoID: string
	lugarID?: string

	aceptaListaDeEspera: boolean
	aceptaCualquierLugar: boolean
}): Promise<boolean> {
	const fx = 'ProfeAPI.AgregarParticipanteAInstancia'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)

	const {
		instancia,
		horario,
		participanteID,
		membresiaID,
		pagoID,
		lugarID,
		aceptaListaDeEspera,
		aceptaCualquierLugar
	} = datos
	try {
		if (horario.salaID) {
			const gimnasio = unref(useGimnasio().gimnasio)
			if (!gimnasio)
				throw new ErrorAumentado(`${fx}: no hay gimnasio`)

			if (horario.salaID) {
				const validacionDeLugar = RevisarSiUsaSalaYEligeUnLugarValido({ gimnasio, instancia, lugarID })

				if (!validacionDeLugar.reservable && !aceptaListaDeEspera)
					throw new ErrorAumentado('lugarNoReservable', { datos: { lugarID, validacionDeLugar } })
			}
		}

		const headers = HeadersConAuth()
		const respuesta = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/profe/participante`,
			method: 'post',
			headers,
			data: {
				fechaYMD: instancia.fechaYMD,
				claseID: instancia.claseID,
				horarioID: instancia.horarioID,
				participanteID,
				membresiaID,
				pagoID,
				lugarID,
				aceptaListaDeEspera,
				aceptaCualquierLugar
			}
		})
			

		const parserZod = z.discriminatedUnion('ok', [
			z.object({
				ok: z.literal(true)
			}),
			z.object({
				ok: z.literal(false),
				error: ErrorNotificableZod,
				datos: z.any()
			})
		])
		const parseoRespuesta = parserZod.safeParse(respuesta)
		if (!parseoRespuesta.success) {
			throw new ErrorAumentado('respuestaInvalida', {
				respuesta
			})
		}
		const respuestaParseada = parseoRespuesta.data

		await InstanciasAPI.ActualizarInstancias([instancia])

		if (respuestaParseada.ok) {
			notificadorEnApp.exito({
				titulo: i18n('reservaAgregada'),
				texto: '',
				icono: 'majesticons:ticket-check'
			})
			// emit('reservada')
		}
		else if (respuestaParseada.error === 'sistemaNoLoPermite') {
			// const errorID = respuestaParseada.error
			const mensaje = respuestaParseada.datos.mensaje
			notificadorEnApp.error({
				titulo: i18n('noSePudoAgregarReserva'),
				texto: mensaje || '',
				icono: 'uit:exclamation-triangle'
			})
		}
		else {
			const errorID = respuestaParseada.error
			notificadorEnApp.atencion({
				titulo: i18n('noSePudoAgregarReserva'),
				texto: i18nTapi(errorID),
				codigo: errorID,
				icono: i18nIconos[errorID]
			})
		}

		return respuestaParseada.ok
	}
	catch (e) {
		consolo.error('error', e)
		throw new ErrorAumentado('error', { error: e })
	}
	finally {
		ObtenerAlgunosParticipantes([participanteID])
		InstanciasAPI.ActualizarInstancias([instancia])
		consolo.groupEnd()
	}
}


async function EliminarReservaParticipante(datos: {
	instancia: Instancia
	participanteID: string
}): Promise<boolean> {
	const fx = 'ProfeAPI.EliminarReservaParticipante'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)

	const { instancia, participanteID } = datos
	try {
		const { claseID, fechaYMD, horarioID } = instancia
		const headers = HeadersConAuth()
		const r = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/profe/reserva`,
			method: 'delete',
			headers,
			data: {
				claseID,
				fechaYMD,
				horarioID,
				participanteID
			}
		})
			

		if (r.ok) {
			notificadorEnApp.exito({
				titulo: i18n('hecho'),
				texto: i18n('reservaEliminada')
			})
		}
		else {
			notificadorEnApp.error({
				titulo: i18n('intentoFallido'),
				texto: i18n('noSePudoEliminarReserva')
			})
		}

		return r.ok
	}
	catch (e) {
		consolo.error(`${fx}: error`, e)
		throw new ErrorAumentado(`${fx}: error`, { error: e })
	}
	finally {
		ObtenerAlgunosParticipantes([participanteID])
		InstanciasAPI.ActualizarInstancias([instancia])
		consolo.groupEnd()
	}
}

//

async function MarcarAsistenciaAlumno(datos: {
	instancia: Instancia
	reservaID: string
	participanteID: string
	asistio: boolean
}): Promise<boolean> {
	const fx = 'ProfeAPI.MarcarAsistenciaAlumno'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)

	const { instancia, reservaID, participanteID, asistio } = datos
	const { claseID, fechaYMD, horarioID } = instancia
	try {
		const headers = HeadersConAuth()
		const r = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/profe/marcarAsistencia`,
			method: 'post',
			headers,
			data: {
				claseID,
				fechaYMD,
				horarioID,

				reservaID,
				asistio
			}
		})
			

		// Esperar que ambas funciones terminen

		const RespuestaZod = z.object({
			instancias: z.record(z.string(), InstanciaZod).optional(),
			participantes: ListaDeParticipantesZod.optional()
		})
		const parseo = RespuestaZod.safeParse(r)
		if (!parseo.success) {
			await Promise.all([
				ObtenerAlgunosParticipantes([participanteID]),
				InstanciasAPI.ActualizarInstancias([instancia])
			])

			throw new ErrorAumentado('respuestaInvalida', {
				respuesta: r
			})
		}

		if (parseo.data.instancias) {
			await InstanciasAPI.IntegrarInstancias(parseo.data.instancias)
		} else InstanciasAPI.ActualizarInstancias([instancia])

		const gim = unref(gimnasio)
		if (parseo.data.participantes && gim) {
			await UsuariosAPI.IntegrarParticipantes({
				gimnasio: gim,
				participantes: parseo.data.participantes
			})
		} else ObtenerAlgunosParticipantes([participanteID])

		return r.ok
	}
	catch (e) {
		consolo.error(`${fx}: error`, e)
		throw new ErrorAumentado(`${fx}: error`, { error: e })
	}
	finally {
		consolo.groupEnd()
	}
}

export const ProfeAPI = {
	ImpartirClase,
	SoltarClase,

	ListarParticipantes,
	BuscarParticipantes,
	ObtenerAlgunosParticipantes,
	ObtenerParticipantePorBMID,
	AgregarParticipanteAInstancia,

	EliminarReservaParticipante,

	MarcarAsistenciaAlumno,

	AccederASesionOnline
}
