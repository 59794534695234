<template lang="pug">
NuxtLink.UiLink(v-if="para"
	:to="para"
	:activeClass="claseNuxtLinkActivo"
	:exactActiveClass="claseNuxtLinkActivoExacto"
	:class="[activoSiContieneUrlActual && contieneUrlActual ? claseNuxtLinkActivo : '', { preIcono: slots.preIcono, postIcono: slots.postIcono, intocable: enfriandoCambioDeRuta, oscuro: unref(TemaOscuroAPI.activo) }]"
	@click="emitirClick"
	@touchstart.passive="detectorToque"
	v-slot="nLink")
	.contenidoLink(:class="{ alCentro }")
		.zonaIcono.preIcono.fa00.flex.jcc.aic(v-if="cargando || slots.preIcono || slots.postIcono")
			div.preIcono(v-if="slots.preIcono")
				slot(name="preIcono")

			div.cargando(v-else-if="cargando")
				UiLoader

		.contenido(v-if="slots.default")
			slot(:activo="nLink?.isActive" :exacto="nLink?.isExactActive" :contieneUrlActual="contieneUrlActual")

		.zonaIcono.postIcono.fa00.flex.jcc.aic.minw1(v-if="slots.postIcono")
			div(v-if="slots.postIcono")
				slot(name="postIcono")
</template>

<script setup lang="ts">
import { emitirEventoDeApp } from '@base/composables/eventosDeApp';

const props = withDefaults(
	defineProps<{
		para: string
		cargando?: boolean
		alCentro?: boolean
		asimetrico?: boolean
		sinPadding?: boolean
		exacto?: boolean

		claseLinkActivo?: string
		claseLinkActivoExacto?: string

		activoSiContieneUrlActual?: boolean
	}>(),
	{
		cargando: false,
		alCentro: false,
		asimetrico: false,
		sinPadding: false,
		exacto: false,
		activoSiContieneUrlActual: true
	}
)

const emit = defineEmits([
	'click',
	'abierto',
	'ventanaExternaCargada',
	'ventanaExternaCerrada'
])

const contieneUrlActual = computed((): boolean => {
	const esInterno = unref(props.para).startsWith('/')
	if (!esInterno)
		return false

	const path = props.para
	const route = useRoute()
	const pathActual = route.path

	return pathActual.startsWith(path)
})

const slots = useSlots()

const claseNuxtLinkActivo = computed(() => {
	const exacto = unref(props.exacto)
	if (exacto)
		return ''
	return props.claseLinkActivo ?? 'activo'
})

const claseNuxtLinkActivoExacto = computed(() => {
	return props.claseLinkActivoExacto ?? 'activoExacto'
})

function emitirClick(event: MouseEvent) {
	if (unref(enfriandoCambioDeRuta))
		return
	emit('click')

	const para = unref(props.para)
	
	consolo.log('link emitirClick, para:', para)
	if (!para) {
		console.error('!para')
		event.preventDefault()
		return
	}

	// Detectar si la url es interna o externa, si tiene origin o no
	const externo = (() => {
		try {
			const uri = new URL(para)
			consolo.log('uri', uri)
			return uri.origin !== window.location.origin
		}
		catch (error) {
			// consolo.log('error', error)
			return false
		}
	})()

	if (!externo) {
		consolo.log('!externo')
		emitirEventoDeApp('rutaCambiada', para)
		return true
	}
	else {
		consolo.log('emitirClick externo!')

		// Link Externo
		event.preventDefault()
		const ventana = abrirEnNavegador(para)
		emit('abierto')
		ventana.on('cargada', () => {
			consolo.log('ventanaExternaCargada', `@ ${para}`)
			emit('ventanaExternaCargada', event)
		})
		ventana.on('cerrada', () => {
			consolo.log('ventanaExternaCerrada', `@ ${para}`)
			emit('ventanaExternaCerrada', event)
		})
	}
}
</script>

<script lang="ts">
export default defineComponent({
	name: 'UiLink'
})
</script>

<style lang="sass" scoped>
@use 'sass:color'
@use '@base/sass/comun'

.UiLink
	// border-radius: comun.$radio
	padding-left: initial
	padding-right: initial
	// text-align: center
	+comun.medium
	font-size: 1rem
	position: relative
	z-index: 1

	cursor: pointer
	line-height: 1

	outline: 0
	border: 0 solid #333

	color: var(--linkColor)
	text-decoration-color: var(--linkColor)
	text-decoration: none

	&:visited
		color: var(--linkColor)
	+comun.hoverDeMouse
		&:hover, &:focus
			color: var(--linkColorHover)
	&:active
		color: var(--linkColorActive)
	&.activo
		// color: var(--linkActivo)
	&.activoExacto
		// color: var(--linkActivoExacto)
		cursor: default

	.contenidoLink
		display: inline
		max-width: 100%
		user-select: none

		.contenido
			display: inline
			max-width: 100%

	&.db	.contenidoLink
		display: block
		height: 100%
		.contenido
			display: block
			height: 100%
	.contenidoLink

		&.alCentro
			justify-content: center

		&[disabled]
			filter: saturate(0)
			opacity: .5
			cursor: not-allowed

		.zonaIcono
			flex: auto 0 0
			min-width: 1em
			min-height: 1em
			font-size: 1.2em
.UiLink.sinTexto:not(.preIcono)
	.preIcono
		display: none
.UiLink.sinTexto:not(.postIcono)
	.postIcono
		display: none
	.zonaIcono
		font-size: 1.4em
.UiLink:not(.sinTexto)
	.zonaIcono.preIcono
		margin-right: 1em
	.zonaIcono.postIcono
		margin-left: 1em

.UiLink
	&.primario
		color: var(--primarioColor)
	&.exito
		color: var(--exitoColor)
	&.atencion
		color: var(--atencionColor)
	&.peligro
		color: var(--peligroColor)
	&.texto
		color: var(--bodyColor)
		&:visited
			color: var(--bodyColor)

// html.dark .UiLink
// 	// &.alAire,
// 	// &.casiAlAire
// 	.contenidoLink
// 		filter: brightness(1.2)

.UiLink
	&:disabled
		filter: saturate(.2)
		opacity: .5
		cursor: not-allowed
	&.cargando
		// filter: saturate(0)
		opacity: .5
		cursor: progress

.UiLink.activo
	&:deep(.soloInactivo)
		display: none
	&.desactivarSiActivo
		pointer-events: none
.UiLink:not(.activo)
	&:deep(.soloActivo)
		display: none
.UiLink:not(.activoExacto)
	&:deep(.soloActivoExacto)
		display: none
</style>
