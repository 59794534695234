<template lang="pug">
.version.fz07rem.fwm.tar.usn

	//- BloqueoOrientacion.z0.posr
	.px1ud.aic.posr(style="z-index: 10;")
		ClientOnly.aic.gap.ffrw(v-if="contextoApp.buildConfig.modo !== 'produccion'") 
			.posf.l0.r0.atencionFondo.h1em.z-1(v-if="staging" style="mask-image: linear-gradient(to left, rgba(0,0,0,.4) 10em, black 20em)")

			.aic
				div {{ hora }} {{  i18n('zonaHoraria') }} {{ reloj.format('Z') }}&nbsp&nbsp&nbsp

			.fwb(v-if="staging") STAGING&nbsp
			.fwb(v-else) SANDBOX&nbsp
			
			.fwl(v-if="contextoApp.buildConfig.modo !== 'dev'") ({{contextoApp.buildConfig.modo}})&nbsp
		.op05 v{{ contextoApp.buildConfig.version }} #[span(v-for="rol in susRoles") {{ i18n(rol) }}]
</template>

<script lang="ts" setup>
const staging = contextoApp.buildConfig.adminUrl.startsWith('https://staging')

const hora = computed(() => unref(reloj).format('HH:mm'))
const i18n = TrosettaAPI.crear({
	staff: {
		es: 'Staff',
		en: 'Staff',
		pt: 'Staff'
	},
	coach: {
		es: 'Coach',
		en: 'Coach',
		pt: 'Coach'
	},
	admin: {
		es: 'Admin',
		en: 'Admin',
		pt: 'Admin'
	},
	recepcion: {
		es: 'Recepción',
		en: 'Reception',
		pt: 'Recepção'
	},
	head_coach: {
		es: 'Head Coach',
		en: 'Head Coach',
		pt: 'Head Coach'
	},
	super_admin: {
		es: 'Super Admin',
		en: 'Super Admin',
		pt: 'Super Admin'
	},
	alumno: {
		es: '',
		en: '',
		pt: ''
	},
	zonaHoraria: {
		es: 'Zona horaria',
		en: 'Time zone',
		pt: 'Fuso horário'
	}
})

const susRoles = computed(() => unref(usuario)?.roles || [])
</script>

<style lang="sass" scoped>
@use '@base/sass/comun'
.version
	position: fixed
	z-index: 100
	top: 0
	right: 0
	line-height: 1
	// border: 2px solid red
	padding-top: const(safe-area-inset-top, .5rem)
	padding-top: env(safe-area-inset-top, .5rem)
	+comun.movil
		padding-top: const(safe-area-inset-top, .1rem)
		padding-top: env(safe-area-inset-top, .1rem)
	+comun.tablet
		padding-top: max(const(safe-area-inset-top, .5rem), .5rem)
		padding-top: max(env(safe-area-inset-top, .5rem), .5rem)
</style>
