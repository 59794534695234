import _ from 'lodash'
import { z } from 'zod'

import emisorEventosObjeto from '@base/lib/emisorEventosObjeto'


export const enCliente = import.meta.client

// * Eventos
const emisorEventos = { ...emisorEventosObjeto }

const puestaEnMarchaBaseRef = ref<boolean | null>(null)
const inicializandoBaseRef = ref(false)

export const BasePuestaEnMarcha = computed(() => puestaEnMarchaBaseRef.value)

export const inicializandoBase = computed(() => inicializandoBaseRef.value)

async function init() {
	const fx = 'App base init'
	try {
		if (inicializandoBaseRef.value) {
			consolo.warn('Ya se está inicializando la app')
			return
		}
		inicializandoBaseRef.value = true
		console.log(fx)

		const windowResize = _.throttle(() => { emitirEventoDeApp('windowResize', true) }, 500)
		window.addEventListener('resize', windowResize)

		await Promise.all([
			TemaOscuroAPI.init(),
			NotificacionesAPI.init(),
		])

		puestaEnMarchaBaseRef.value = true
	}
	catch (error) {
		consolo.error(fx, error)
		puestaEnMarchaBaseRef.value = false
	}
	finally {
		inicializandoBaseRef.value = false
	}
}


export const AppZod = z.object({
	nombre: z.string(),
	textos: z
		.object({
			fraseInicio: z.string()
		})
		.optional(),

	permisosDeAnonimo: z.object({
		crearCuenta: BooleanOpcionalZod,
		ingresar: BooleanOpcionalZod,
		cambiarPass: BooleanOpcionalZod
	}).optional()
})

export type App = z.infer<typeof AppZod>


export const AppBaseAPI = {
	...emisorEventos,
	init
}

