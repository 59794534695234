import { default as ingresoZ8jSY18BscMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/acceso/ingreso.vue?macro=true";
import { default as recuperar_45passHeUjpyHuvQMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/acceso/recuperar-pass.vue?macro=true";
import { default as registroCkD3f5fpSVMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/acceso/registro.vue?macro=true";
import { default as accesop2pNptfj6dMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/acceso.vue?macro=true";
import { default as eventosVa3SoHI8rkMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/eventos.vue?macro=true";
import { default as horariosDKuqWsLhIcMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/horarios.vue?macro=true";
import { default as indexTHAb917eSTMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/index.vue?macro=true";
import { default as index49SN4JCAgqMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/index.vue?macro=true";
import { default as _91noticiaID_93R3FIApewTyMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/noticias/edicion/[noticiaID].vue?macro=true";
import { default as indexENpJvKEq9BMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/noticias/index.vue?macro=true";
import { default as _91noticiaID_931iI2IJziAgMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/noticias/noticia/[noticiaID].vue?macro=true";
import { default as noticiasQHVkIaASB9Meta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/noticias.vue?macro=true";
import { default as _91programaID_93cT4QjJ7BZ1Meta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/programas/[programaID].vue?macro=true";
import { default as index25VRSsIxIWMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/programas/index.vue?macro=true";
import { default as _91galeriaID_93b7C2KuVpx4Meta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/videos/galeria/[galeriaID].vue?macro=true";
import { default as indexuoksnDzNmGMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/videos/index.vue?macro=true";
import { default as _91videoID_93KU2qCd3B5OMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/videos/video/[videoID].vue?macro=true";
import { default as videospnqDKIaOXBMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/videos.vue?macro=true";
import { default as notificadorTna6V5DFdTMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/notificador.vue?macro=true";
import { default as ajustesziSzfrGGp6Meta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/perfil/ajustes.vue?macro=true";
import { default as evaluacionesHmPuzPqAK5Meta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/perfil/evaluaciones.vue?macro=true";
import { default as index9We7jqXlY0Meta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/perfil/index.vue?macro=true";
import { default as membresiasfnAqtzbGDpMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/perfil/membresias.vue?macro=true";
import { default as reservasdmaTSR3UxkMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/reservas.vue?macro=true";
import { default as rutinasOHOFbXgm6XMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/rutinas.vue?macro=true";
import { default as tiendaLqV6BMVOmEMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/tienda.vue?macro=true";
import { default as tiendaDinamicaMVs3NwqWpbMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/tiendaDinamica.vue?macro=true";
import { default as appEiDr5aR4V7Meta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app.vue?macro=true";
import { default as indexzAVNYGyRrpMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/appio/index.vue?macro=true";
import { default as bienvenidarmaOci2IFxMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/bienvenida.vue?macro=true";
import { default as holaRe4SKcSFVlMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/hola.vue?macro=true";
import { default as indexZHGmk3rIChMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/index.vue?macro=true";
import { default as sesion6KRX29sVkhMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/redireccion/sesion.vue?macro=true";
import { default as tarjetaFuONwzG1LeMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/redireccion/tarjeta.vue?macro=true";
import { default as referenciahgmrwuWYUlMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/referencia.vue?macro=true";
import { default as botonesiVymubQ4i1Meta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/botones.vue?macro=true";
import { default as calendarioSFihKepPmpMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/calendario.vue?macro=true";
import { default as cuadrosFlotanteslyX4nh2lZ4Meta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/cuadrosFlotantes.vue?macro=true";
import { default as divsvoAWu5Z4P2Meta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/divs.vue?macro=true";
import { default as indexWldJ0rKNNWMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/index.vue?macro=true";
import { default as inputsYwXAYxPyu9Meta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/inputs.vue?macro=true";
import { default as links4d3Fs9PAm7Meta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/links.vue?macro=true";
import { default as nuevosBotones3W7EOgZC6MMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/nuevosBotones.vue?macro=true";
import { default as tarjetasRzmKQZyvxPMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/tarjetas.vue?macro=true";
import { default as videosgAZeF7EIAEMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/videos.vue?macro=true";
import { default as conMenu6Xm1T9O81JMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/conMenu.vue?macro=true";
import { default as indexQEPSSkfSNbMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/index.vue?macro=true";
import { default as temagXlw6OyFhmMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/tema.vue?macro=true";
import { default as textoEaywgcQR4UMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/texto.vue?macro=true";
import { default as visor_45uiFeUCuGWzAXMeta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui.vue?macro=true";
import { default as component_45stubGlz93JD6Y4Meta } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGlz93JD6Y4 } from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "acceso",
    path: "/acceso",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/acceso.vue").then(m => m.default || m),
    children: [
  {
    name: "acceso-ingreso",
    path: "ingreso",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/acceso/ingreso.vue").then(m => m.default || m)
  },
  {
    name: "acceso-recuperar-pass",
    path: "recuperar-pass",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/acceso/recuperar-pass.vue").then(m => m.default || m)
  },
  {
    name: "acceso-registro",
    path: "registro",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/acceso/registro.vue").then(m => m.default || m)
  }
]
  },
  {
    name: appEiDr5aR4V7Meta?.name,
    path: "/app",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app.vue").then(m => m.default || m),
    children: [
  {
    name: "app-eventos",
    path: "eventos",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/eventos.vue").then(m => m.default || m)
  },
  {
    name: "app-horarios",
    path: "horarios",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/horarios.vue").then(m => m.default || m)
  },
  {
    name: "app",
    path: "",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: "app-mi-centro",
    path: "mi-centro",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/index.vue").then(m => m.default || m)
  },
  {
    name: noticiasQHVkIaASB9Meta?.name,
    path: "mi-centro/noticias",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/noticias.vue").then(m => m.default || m),
    children: [
  {
    name: "app-mi-centro-noticias-edicion-noticiaID",
    path: "edicion/:noticiaID()",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/noticias/edicion/[noticiaID].vue").then(m => m.default || m)
  },
  {
    name: "app-mi-centro-noticias",
    path: "",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/noticias/index.vue").then(m => m.default || m)
  },
  {
    name: "app-mi-centro-noticias-noticia-noticiaID",
    path: "noticia/:noticiaID()",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/noticias/noticia/[noticiaID].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app-mi-centro-programas-programaID",
    path: "mi-centro/programas/:programaID()",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/programas/[programaID].vue").then(m => m.default || m)
  },
  {
    name: "app-mi-centro-programas",
    path: "mi-centro/programas",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/programas/index.vue").then(m => m.default || m)
  },
  {
    name: videospnqDKIaOXBMeta?.name,
    path: "mi-centro/videos",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/videos.vue").then(m => m.default || m),
    children: [
  {
    name: "app-mi-centro-videos-galeria-galeriaID",
    path: "galeria/:galeriaID()",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/videos/galeria/[galeriaID].vue").then(m => m.default || m)
  },
  {
    name: "app-mi-centro-videos",
    path: "",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "app-mi-centro-videos-video-videoID",
    path: "video/:videoID()",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/mi-centro/videos/video/[videoID].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app-notificador",
    path: "notificador",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/notificador.vue").then(m => m.default || m)
  },
  {
    name: "app-perfil-ajustes",
    path: "perfil/ajustes",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/perfil/ajustes.vue").then(m => m.default || m)
  },
  {
    name: "app-perfil-evaluaciones",
    path: "perfil/evaluaciones",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/perfil/evaluaciones.vue").then(m => m.default || m)
  },
  {
    name: "app-perfil",
    path: "perfil",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/perfil/index.vue").then(m => m.default || m)
  },
  {
    name: "app-perfil-membresias",
    path: "perfil/membresias",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/perfil/membresias.vue").then(m => m.default || m)
  },
  {
    name: "app-reservas",
    path: "reservas",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/reservas.vue").then(m => m.default || m)
  },
  {
    name: "app-rutinas",
    path: "rutinas",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/rutinas.vue").then(m => m.default || m)
  },
  {
    name: "RootTienda",
    path: "tienda",
    meta: tiendaLqV6BMVOmEMeta || {},
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/tienda.vue").then(m => m.default || m)
  },
  {
    name: "app-tiendaDinamica",
    path: "tiendaDinamica",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/app/tiendaDinamica.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "appio",
    path: "/appio",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/appio/index.vue").then(m => m.default || m)
  },
  {
    name: "BienvenidaParent",
    path: "/bienvenida",
    meta: bienvenidarmaOci2IFxMeta || {},
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/bienvenida.vue").then(m => m.default || m)
  },
  {
    name: "Hola",
    path: "/hola",
    meta: holaRe4SKcSFVlMeta || {},
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/hola.vue").then(m => m.default || m)
  },
  {
    name: "RootIndex",
    path: "/",
    meta: indexZHGmk3rIChMeta || {},
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "redireccion-sesion",
    path: "/redireccion/sesion",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/redireccion/sesion.vue").then(m => m.default || m)
  },
  {
    name: "redireccion-tarjeta",
    path: "/redireccion/tarjeta",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/redireccion/tarjeta.vue").then(m => m.default || m)
  },
  {
    name: "referencia",
    path: "/referencia",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/referencia.vue").then(m => m.default || m)
  },
  {
    name: visor_45uiFeUCuGWzAXMeta?.name,
    path: "/visor-ui",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui.vue").then(m => m.default || m),
    children: [
  {
    name: "visor-ui-componentes-botones",
    path: "componentes/botones",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/botones.vue").then(m => m.default || m)
  },
  {
    name: "visor-ui-componentes-calendario",
    path: "componentes/calendario",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/calendario.vue").then(m => m.default || m)
  },
  {
    name: "visor-ui-componentes-cuadrosFlotantes",
    path: "componentes/cuadrosFlotantes",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/cuadrosFlotantes.vue").then(m => m.default || m)
  },
  {
    name: "visor-ui-componentes-divs",
    path: "componentes/divs",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/divs.vue").then(m => m.default || m)
  },
  {
    name: "visor-ui-componentes",
    path: "componentes",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/index.vue").then(m => m.default || m)
  },
  {
    name: "visor-ui-componentes-inputs",
    path: "componentes/inputs",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/inputs.vue").then(m => m.default || m)
  },
  {
    name: "visor-ui-componentes-links",
    path: "componentes/links",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/links.vue").then(m => m.default || m)
  },
  {
    name: "visor-ui-componentes-nuevosBotones",
    path: "componentes/nuevosBotones",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/nuevosBotones.vue").then(m => m.default || m)
  },
  {
    name: "visor-ui-componentes-tarjetas",
    path: "componentes/tarjetas",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/tarjetas.vue").then(m => m.default || m)
  },
  {
    name: "visor-ui-componentes-videos",
    path: "componentes/videos",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/componentes/videos.vue").then(m => m.default || m)
  },
  {
    name: "visor-ui-conMenu",
    path: "conMenu",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/conMenu.vue").then(m => m.default || m)
  },
  {
    name: "visor-ui",
    path: "",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/index.vue").then(m => m.default || m)
  },
  {
    name: "visor-ui-tema",
    path: "tema",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/tema.vue").then(m => m.default || m)
  },
  {
    name: "visor-ui-texto",
    path: "texto",
    component: () => import("/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/pages/visor-ui/texto.vue").then(m => m.default || m)
  }
]
  },
  {
    name: component_45stubGlz93JD6Y4Meta?.name,
    path: "/home",
    component: component_45stubGlz93JD6Y4
  },
  {
    name: component_45stubGlz93JD6Y4Meta?.name,
    path: "/bienvenida/entrada",
    component: component_45stubGlz93JD6Y4
  },
  {
    name: component_45stubGlz93JD6Y4Meta?.name,
    path: "/preparacion",
    component: component_45stubGlz93JD6Y4
  },
  {
    name: component_45stubGlz93JD6Y4Meta?.name,
    path: "/perfil",
    component: component_45stubGlz93JD6Y4
  },
  {
    name: component_45stubGlz93JD6Y4Meta?.name,
    path: "/registro",
    component: component_45stubGlz93JD6Y4
  },
  {
    name: component_45stubGlz93JD6Y4Meta?.name,
    path: "/ingreso",
    component: component_45stubGlz93JD6Y4
  },
  {
    name: component_45stubGlz93JD6Y4Meta?.name,
    path: "/app/inicio",
    component: component_45stubGlz93JD6Y4
  },
  {
    name: component_45stubGlz93JD6Y4Meta?.name,
    path: "/app/videos",
    component: component_45stubGlz93JD6Y4
  },
  {
    name: component_45stubGlz93JD6Y4Meta?.name,
    path: "/app/ajustes",
    component: component_45stubGlz93JD6Y4
  },
  {
    name: component_45stubGlz93JD6Y4Meta?.name,
    path: "/app/evaluaciones",
    component: component_45stubGlz93JD6Y4
  },
  {
    name: component_45stubGlz93JD6Y4Meta?.name,
    path: "/recuperar-pass",
    component: component_45stubGlz93JD6Y4
  },
  {
    name: component_45stubGlz93JD6Y4Meta?.name,
    path: "/test",
    component: component_45stubGlz93JD6Y4
  }
]