import _ from 'lodash'
import type { RegistroDePerfilesEnGimnasio } from '@comun/types'

// * Gimnasio

async function autoElegirPerfilEnGimnasio(perfilesEnGimnasios: RegistroDePerfilesEnGimnasio) {
	const fx = 'autoElegirPerfilEnGimnasio'
	consolo.log(fx)
	const timerID = `${fx} ${MiniID()}`
	consolo.time(timerID)
	try {
		// * Si hay un solo gimnasio con membresias vigentes, cambiar a ese
		const conMembresiasVigentes = _.pickBy(perfilesEnGimnasios, (peg) => {
			if (!peg.membresias)
				return false
			const mv = filtrarMembresiasVigentes(peg.membresias)
			return !_.isEmpty(mv)
		})

		if (_.values(conMembresiasVigentes).length === 1) {
			const peg = _.first(_.values(conMembresiasVigentes))!
			consolo.log('autoElegirPerfilEnGimnasio x conMembresiasVigentes', 'Seleccionando gimnasio', peg.gimnasioID)
			await AppAPI.SeleccionarGimnasio(peg!.gimnasioID)
			return
		}

		const conMembresias = _.pickBy(perfilesEnGimnasios, peg => !_.isEmpty(peg.membresias))

		if (_.values(conMembresias).length === 1) {
			const peg = _.first(_.values(conMembresias))!
			consolo.log('autoElegirPerfilEnGimnasio x conMembresias', 'Seleccionando gimnasio', peg.gimnasioID)
			await AppAPI.SeleccionarGimnasio(peg!.gimnasioID)
			return
		}

		if (_.values(perfilesEnGimnasios).length === 1) {
			const peg = _.first(_.values(perfilesEnGimnasios))!
			consolo.log('autoElegirPerfilEnGimnasio x perfilesEnGimnasios', 'Seleccionando gimnasio', peg.gimnasioID)
			await AppAPI.SeleccionarGimnasio(peg!.gimnasioID)
		}
	}
	finally {
		consolo.timeEnd(timerID)
	}
}

export async function PrepararSesionEnGimnasio() {
	const fx = 'PrepararSesionEnGimnasio'
	const timerID = MiniID()
	consolo.time(timerID)
	try {
		const gID = unref(gimnasioID) ?? await AppAPI.RecuperarSeleccionDeGimnasio()
		const gim = unref(gimnasio)
		const peg = unref(perfilEnGimnasio)
		const pegs = unref(perfilesEnGimnasios)

		consolo.log('gID', gID ? '✅' : '❌')
		consolo.log('gim', gim ? '✅' : '❌')
		consolo.log('peg', peg ? '✅' : '❌')
		consolo.log('pegs', !_.isEmpty(pegs) ? '✅' : '❌')

		const refrescarPegs = (!gID && _.isEmpty(pegs))
		consolo.log('refrescarPegs', refrescarPegs ? '✅' : '❌')

		const esSuperAdmin = unref(usuario)?.roles.includes('super_admin')
		consolo.log('esSuperAdmin', esSuperAdmin)

		if (esSuperAdmin) {
			if (gID && !peg) await PerfilEnGimnasioAPI.ObtenerPerfilEnGimnasio()
			else if (!gID) await GimnasiosAPI.ObtenerDisponibles()
		}
		else {
			const vinculadosRefrescados = refrescarPegs ? await (async () => {
				const pygs = await PerfilesEnGimnasioAPI.ObtenerVinculados()
				if (!pygs) return {}
				return pygs.perfilesEnGimnasios
			})() : pegs

			if (!gID && !_.isEmpty(vinculadosRefrescados)) await autoElegirPerfilEnGimnasio(vinculadosRefrescados)

			const refrescarPeg = gID && (!refrescandoPerfilEnGimnasioPropio && gID && !peg) ? PerfilEnGimnasioAPI.ObtenerPerfilEnGimnasio() : null

			// const refrescarGim = gID && (!gim || gim.gimnasioID !== gID) ? GimnasioAPI.ObtenerGimnasio() : null

			// consolo.log('refrescarGim', refrescarGim ? '✅' : '❌')
			consolo.log('refrescarPeg', refrescarPeg ? '✅' : '❌')

			await Promise.all([
				// refrescarGim,
				refrescarPeg
			])
		}
		if (gim && gim.caracteristicas.noticias) {
			const noticias = unref(useNoticias().noticias)
			if (_.isEmpty(noticias))
				NoticiasAPI.ObtenerNoticias()
		}
	}
	catch (e) {
		consolo.error(fx, e)
	}
	finally {
		consolo.log(fx)
		consolo.timeEnd(timerID)
	}
}

