

export default defineNuxtPlugin((nuxtApp) => {
	// * Sólo en cliente
	if (!import.meta.client) return

	nuxtApp.hook('app:mounted', () => {
		console.log('\n','01.inicio on app:mounted')
		// AppAPI.init()
		alInit()
	})

	// * Detectar cambios de tema configurados en la página
	nuxtApp.hook('app:suspense:resolve', () => {
		console.log('\non app:suspense:resolve')
		refrescarTemaDeRuta()
	})

	nuxtApp.hook('page:loading:end', () => {
		console.log('\non page:loading:end')
		refrescarTemaDeRuta()
	})
})
