import { z } from 'zod'

import _ from 'lodash'
import { ErrorAumentado } from '@base/lib/error'

import emisorEventosObjeto from '@base/lib/emisorEventosObjeto'
import type { GimnasioListado, PerfilEnGimnasio } from '@comun/types'
import { GimnasioListadoZod } from '@comun/types'
import { ErrorNotificableZod, i18nIconos, i18nTapi } from '@base/lib/erroresNotificables'

const ObtenerGimnasiosParser = z.object({
	ok: z.boolean(),
	gimnasios: z.array(GimnasioListadoZod)
})

const ObtenerGimnasiosErrorTAPI = z.object({
	ok: z.literal(false),
	error: ErrorNotificableZod
})

// * Eventos
const emisorEventos = { ...emisorEventosObjeto }

// ESTADOS
const GimnasiosDisponiblesRef = ref<Record<string, GimnasioListado>>({})

export const gimnasiosDisponibles = computed(
	(): Record<string, GimnasioListado> => {
		if (esAppGold) {
			const sedes = unref(sedesGold)
			if (!sedes)
				return {}
			return sedes
		}
		return unref(GimnasiosDisponiblesRef)
	}
)

export type GimnasioListadoConInfoPerfil = GimnasioListado & {
	gperfilEnGimnasio: PerfilEnGimnasio
	peg: PerfilEnGimnasio
	usuarioTienePerfil: boolean
	numeroDeMembresias: number
	numeroDeMembresiasVigentes: number
	nombre: string
}
export type RegistroDeGimnasiosConInfoPerfil = Record<string, GimnasioListadoConInfoPerfil>

export const gimnasiosListado = computed((): GimnasioListadoConInfoPerfil[] => {
	const gims = unref(gimnasiosDisponibles)
	const pegs = unref(perfilesEnGimnasios) || {}

	const conCifrasYNombre: Record<string, GimnasioListadoConInfoPerfil> = {}

	for (const gID in gims) {
		const g = gims[gID]
		const peg = pegs[gID]
		const membresias = peg?.membresias || {}
		const nMembresias = Object.keys(membresias).length

		const vigentes = filtrarMembresiasVigentes(membresias || {})
		const nVigentes = Object.keys(vigentes).length

		const gimListadoConInfo: GimnasioListadoConInfoPerfil = {
			...g,
			gperfilEnGimnasio: peg,
			peg,
			usuarioTienePerfil: peg ? true : !!peg,
			numeroDeMembresiasVigentes: nVigentes,
			numeroDeMembresias: nMembresias,
			nombre: g.perfilDelCentro.nombre
		}

		conCifrasYNombre[gID] = gimListadoConInfo
	}

	const ordenadosPorInscritoYNombre = _.orderBy(
		conCifrasYNombre,
		['numeroDeMembresiasVigentes', 'numeroDeMembresias', 'usuarioTienePerfil', 'nombre'],
		['desc', 'desc', 'asc']
	)

	return ordenadosPorInscritoYNombre
})

async function Importar(): Promise<boolean> {
	const fx = 'GimnasiosAPI.Importar'
	const timerID = MiniID()
	consolo.time(timerID)
	try {
		const ruta = `${contextoApp.buildConfig.apiUrl}/boxmagic/gimnasios/importar`
		if (!token.value)
			throw new ErrorAumentado('noHayToken', { origen: fx })
		const respuesta = await axiosWorker({
			url: ruta,
			method: 'get',
			headers: HeadersConAuth()
		})

		// consolo.log(fx, 'r', respuesta)
		return respuesta.ok
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		consolo.log(fx)
		consolo.timeEnd(timerID)
	}
}

export const refrescandoGimnasiosDisponiblesRef = ref(false)
export const refrescandoGimnasiosDisponibles = computed(() => unref(refrescandoGimnasiosDisponiblesRef))

export async function integrarGimnasiosDesdeVinculados(registroDeGimnasios: Record<string, GimnasioListado>) {
	const fx = 'GimnasiosAPI.integrarGimnasiosDesdeVinculados'
	const timerID = MiniID()
	consolo.time(timerID)
	consolo.log(fx)
	const registroDeGimnasiosIntegrado: Record<string, GimnasioListado> = GimnasiosDisponiblesRef.value || {}
	_.forEach(registroDeGimnasios, (gimListado) => {
		const existente = registroDeGimnasiosIntegrado[gimListado.gimnasioID]
		registroDeGimnasiosIntegrado[gimListado.gimnasioID] = Object.assign(existente ?? {}, gimListado)
	})
	GimnasiosDisponiblesRef.value = registroDeGimnasiosIntegrado

	consolo.timeEnd(timerID)
	consolo.log(fx, ', fin\n')
}

async function ObtenerDisponibles(): Promise<boolean> {
	const fx = 'GimnasiosAPI.ObtenerDisponibles'
	const timerID = MiniID()
	consolo.time(timerID)
	try {
		if (esAppGold)
			return true
		const ruta = `${contextoApp.buildConfig.apiUrl}/boxmagic/gimnasios/disponibles`
		if (!token.value) {
			consolo.warn(fx, 'no hay token')
			return false
		}

		refrescandoGimnasiosDisponiblesRef.value = true
		const respuesta = await axiosWorker({
			url: ruta,
			headers: HeadersConAuth()
		})

		// consolo.log(fx, 'r', respuesta)

		if (!respuesta.ok) {
			const errorParseado = ObtenerGimnasiosErrorTAPI.parse(respuesta)

			notificadorEnApp.atencion({
				titulo: i18nTapi('falloElIngreso'),
				texto: i18nTapi(errorParseado.error),
				icono: i18nIconos[errorParseado.error] || undefined
			})
			return false
		}

		const respuestaParseada = ObtenerGimnasiosParser.parse(respuesta)

		const gimnasiosDisponibles: Record<string, GimnasioListado> = {}
		respuestaParseada.gimnasios.forEach((gimnasio) => {
			gimnasiosDisponibles[gimnasio.gimnasioID] = gimnasio
		})

		// consolo.log(fx, 'gimnasiosDisponibles', gimnasiosDisponibles)

		GimnasiosDisponiblesRef.value = gimnasiosDisponibles
		emisorEventos.emit('gimnasiosDisponibles', gimnasiosDisponibles)

		return true
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		refrescandoGimnasiosDisponiblesRef.value = false
		consolo.log(fx)
		consolo.timeEnd(timerID)
	}
}

function Limpiar() {
	GimnasiosDisponiblesRef.value = {}
}

export const GimnasiosAPI = {
	...emisorEventos,
	Importar,
	ObtenerDisponibles,
	Limpiar
}

export function useGimnasios() {
	return {
		gimnasiosDisponibles,
		gimnasiosListado,
		refrescandoGimnasiosDisponibles
	}
}
