<template lang="pug">
button.UiBoton(@click.prevent="emitirClickConCalma"
	@touchstart.passive="detectorToque"
	@mousedown="emitirSiCorresponde"
	:class="{ cargando, preIcono: $slots.preIcono, postIcono: slots.postIcono, simetrico, oscuro: unref(TemaOscuroAPI.activo) }"
	:disabled="disabled")

	// Indicador de componente en dev

	.contenidoBoton.ffrn.gap05em(:class="{ alCentro }")
		.zonaIcono.preIcono.fa00.aic(v-if="cargando || ($slots && ($slots.preIcono || $slots.postIcono))")

			div(v-if="cargando")
				UiLoader

			div(v-else-if="$slots?.preIcono")
				slot(name="preIcono")

		.contenido(v-if="$slots?.default")
			slot

		.zonaIcono.postIcono.fa00.minw1(v-if="(simetrico && $slots.preIcono) || $slots.postIcono || cargando")
			div(v-if="slots.postIcono")
				slot(name="postIcono")
			div(v-else-if="cargando")
				.w1.h1
</template>

<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		cargando?: boolean
		alCentro?: boolean
		simetrico?: boolean
		intocable?: boolean
		disabled?: boolean
		emitirAlPresionar?: boolean
	}>(),
	{
		cargando: false,
		alCentro: false,
		simetrico: false,
		intocable: false,
		disabled: false,
		emitirAlPresionar: false
	}
)

const emit = defineEmits(['click'])

defineComponent({
	name: 'UiBoton'
})
const slots = useSlots()

const { cargando, alCentro, simetrico, disabled }
	= toRefs(props)

function emitirClick(event: MouseEvent) {
	// consolo.log('emitirClick', event)
	if (unref(props.intocable))
		return
	if (unref(props.cargando)) {
		event.preventDefault()
		return
	}
	emit('click', event)
}

const emitirClickConCalma = debounce(emitirClick, 300)

function emitirSiCorresponde(event: MouseEvent) {
	if (!unref(props.emitirAlPresionar))
		return
	event.preventDefault()
	// emitirClick(event)
	emitirClickConCalma(event)
}
</script>

<style lang="sass" scoped>
@use 'sass:color'
@use "sass:math"
@use '@base/sass/comun'

	
.UiBoton
	text-align: center
	position: relative
	z-index: 1
	cursor: pointer
	line-height: 1.4

	outline: 0
	border: 0 solid currentColor

	&::before
		z-index: 0
	&::after
		z-index: 1
	&::before,
	&::after
		+comun.maximizar
		content: ''
		border-radius: inherit
		transition: inherit
		pointer-events: none

	&.preIcono:not(.postIcono):not(.anchoComun):not(.simetrico)
		.postIcono
			display: none
	&.postIcono:not(.preIcono):not(.anchoComun):not(.simetrico)
		.preIcono
			display: none

	.contenidoBoton
		position: relative
		z-index: 2
		&.alCentro
			justify-content: center
			.contenido
				flex: auto 0 1
		div
			display: flex
			justify-content: center
			align-items: center

		&[disabled]
			filter: saturate(0)
			cursor: not-allowed
			&::before,
			&::after
				filter: saturate(0)
		.contenido
			flex: auto 1 1
			max-width: 100%

	&:focus-visible
		outline: 4px solid currentColor

	.zonaIcono
		min-width: 1em
		min-height: 1em
		line-height: 0
	&.vertical
		.contenidoBoton
			flex-direction: column
			justify-content: center
			align-items: center

		.zonaIcono
			&.preIcono
				margin-bottom: 0.2em
				margin-right: 0
			&.postIcono
				margin-top: .5em
				margin-left: 0

	&:not(.sinPadding)

		$min: #{ math.div(14,17) }rem
		$lado: #{ math.div(20,17) }rem
		padding: $min $lado
		&.circular,
		&.cuadrado
			padding: $min
		&.chico
			$min: #{math.div(7,17)}rem
			$lado: #{math.div(14,17)}rem
			padding: $min $lado
			&.circular,
			&.cuadrado
				padding: $min
		&.mini
			$min: #{math.div(4,17)}rem
			$lado: #{math.div(10,17)}rem
			padding: $min $lado
			&.circular,
			&.cuadrado
				padding: $min

		&.vertical
			padding-top: 1em
			padding-bottom: 1em
		// &.p0
		// 	padding-left: 0
		// 	padding-right: 0
		// &.padx
		// 	padding-left: 1em
		// 	padding-right: 1em
		// &.circular
		// 	padding: 1em
		// &.cuadrado
		// 	padding: 1em

	// Radio

	&:not(.radioS):not(.radioL)
		border-radius: .75rem
		&.rectangular
			border-radius: .4rem
		&.redondeado,
		&.circular,
		&.chico,
		&.mini
			border-radius: 10em

	// Altura y ancho mínimos

	&:not(.sinMinimos)
		min-height: #{math.div(50,17)}rem
		min-width: #{math.div(50,17)}rem
		&.chico
			min-height: #{math.div(34,17)}rem
			min-width: #{math.div(34,17)}rem
		&.mini
			min-height: #{math.div(28,17)}rem
			min-width: #{math.div(28,17)}rem

	// Font sizes
	&:not(.sinFz)
		+comun.textoDelBody
		&.chico,
		&.mini
			+comun.subencabezado

	&.cuadrado,
	&.circular
		.contenido
			display: none
		.postIcono
			display: none
	&.circular
		.contenidoBoton
			justify-content: center
			align-items: center
		.zonaIcono
			min-width: 0
			&.preIcono
				margin-right: 0
			&.postIcono
				margin-left: 0

	&.cuadrado
		.contenidoBoton
			justify-content: center
			align-items: center
		.zonaIcono
			min-width: 0
			&.preIcono
				margin-right: 0
			&.postIcono
				margin-left: 0

	&.bordeado,
	&.bordeadoSuave
		&::before
			content: ''
			z-index: 2
		&.alAire::after
			content: ''

// Colores
.UiBoton
	transition: all .3s cubic-bezier(0, 1.45, 0.66, 0.78)
	&
		color: white
		background-color: transparent
	&::after
		background-image: none
		background-color: var(--primarioFondo)
		border-color: var(--primarioFondo)
		border-color: var(--primarioFondo)
	&::after
		color: var(--primarioColor)
	&.bordeado, &.bordeadoSuave
		&::before
			color: var(--primarioColor)
	&.link
		&::before
			color: var(--linkColor)
		&::after
			background-color: var(--linkFondo, var(--linkColor))
	&.activo
		&::before
			color: var(--activoColor)
		&::after
			background-color: var(--activoFondo, var(--activoColor))
	&.gradiente
		&::before
			color: var(--primarioColor)
			color: var(--gradienteColor)
		&::after
			background-image: var(--gradienteFondo)
		// &:focus-visible
		// 	outline-color: var(--primarioFondo)
	&.exito
		&::before
			color: var(--exitoFondo, var(--exitoColor))
		&::after
			background-color: var(--exitoFondo, var(--exitoColor))
	&.atencion
		&::before
			color: var(--atencionFondo, var(--atencionColor))
		&::after
			background-color: var(--atencionFondo, var(--atencionColor))
	&.error,
	&.peligro
		&::before
			color: var(--peligroFondo, var(--peligroColor))
		&::after
			background-color: var(--peligroFondo, var(--peligroColor))
	&.texto,

	&.textoSuave
		color: var(--bodyColor)
		&::before
			color: var(--bodyColor)
		&::after
			color: var(--bodyColor)
			background-color: var(--bodyFondo)
	// &.texto
	// 	color: var(--bodyColor)
	// 	&::after
	// 		color: var(--bodyColor)
	// 		background-color: var(--bodyFondo)

	&.sobreFondo
		background-color: var(--bodyFondo)
		background-image: none
		// &.oscuro
	&.alAire
		&, &::before, &::after
			background-color: transparent
			background-image: none
	&.bordeado
		&::before
			border-width: .2em
			border-style: solid
			border-color: inherit
	&.bordeadoSuave
		&::before
			border-width: .1rem
			border-style: solid
			border-color: inherit
	&.bordeado,
	&.bordeadoSuave
		&.bordeIncoloro
			&::before
				filter: saturate(0)
	&.alAire,
	&.casiAlAire,
	&.sobreFondo
		&, &::before, &::after
			color: var(--primarioColor)
		&.link
			&, &::before, &::after
				color: var(--linkColor)
		&.activo
			&, &::before, &::after
				color: var(--activoColor)

		&.gradiente
			.contenidoBoton
				background-clip: text
				background-image: var(--gradienteColor)
				color: transparent
				.zonaIcono
					color: var(--primarioColor)
					&.postIcono
						color: var(--primarioColor)
		&.exito
			&, &::before, &::after
				color: var(--exitoColor)
		&.atencion
			&, &::before, &::after
				color: var(--atencionColor)
		&.error, &.peligro
			&, &::before, &::after
				color: var(--peligroColor)
		// &.texto
		// 	&, &::before, &::after
		// 		color: var(--bodyColor-reverso)
	&.texto, &.textoSuave
		&, &::before, &::after
			color: var(--bodyColor)

	// Opacidades
	&.textoSuave
		&::after
			opacity: 0.69
		.contenidoBoton
			opacity: .69
	&.casiAlAire,
	&.sobreFondo
		&::after
			opacity: .2
		// &.oscuro::after
		// 	opacity: .2
		// &.textoSuave::after,
		// &.oscuro.textoSuave::after
		// 	opacity: .14

		&.casi
			&::after
				opacity: .1
			&.oscuro::after
				opacity: .1
	&.bordeado,
	&.bordeadoSuave
		&::before
			opacity: .4
		&.oscuro::before
			opacity: .3
		&.semiBordeado
			&::before
				opacity: .069
			// &.oscuro::before
			// 	opacity: .05
	&.bordeadoSuave
		&::before
			opacity: .1
		&.oscuro::before
			opacity: .3

// Transiciones de cambio de estado
.UiBoton
	transform: translateY(0px)
	transition-timing-function: cubic-bezier(0,1.5,0.5,1)
	&:not(:disabled):not(.cargando):not(.inmovil)
		$distanciaArriba: .1rem
		$distanciaCompu: .1rem
		$distanciaMovil: .052rem
		+comun.hoverDeMouse
			&
				transition-duration: .3s
			// Hover de mouse
			&:hover
				transition-duration: .15s
				transition-property: all
				transform: translateY(-#{$distanciaArriba})
				&::after
					filter: brightness(.975)
			// Siendo cliqueado
			&:active:hover
				transform: translateY(0)
				&::after
					filter: brightness(1) saturate(1.05)
				.contenidoBoton
					filter: brightness(.95)
			// * Oscuro
			&.oscuro:hover
				&::after
					filter: brightness(.995)
				.contenidoBoton
					filter: brightness(1.1)
				&:active // Clicando
					&::after
						filter: brightness(.97) saturate(1.1)
					.contenidoBoton
						filter: brightness(1.1) saturate(1.1)
	&:disabled
		filter: saturate(0.2) brightness(0.8)
		cursor: not-allowed

	&.desactivado
		filter: saturate(0.2) brightness(0.8)
		&.oscuro
			filter: saturate(0.2) brightness(0.8)
		// opacity: .5
	&.cargando
		opacity: .8
		cursor: wait
//
</style>
