// composables/useRegistroInteracciones.client.ts
import type { RouteLocationNormalized } from 'vue-router';

// * Tipo para las interacciones, donde el key es el id generado con nanoid
interface Interacciones {
  [key: string]: Interaccion;
}

// * Tipo para las interacciones individuales
interface Interaccion {
  id: string;
  ruta: string;
  horaInicio: string;
  horaFin: string | null;
  tamanoPantalla: TamanoPantalla;
  eventos: EventoInteraccion[];
}

// * Tipo para el tamaño de pantalla
interface TamanoPantalla {
  ancho: number;
  alto: number;
}

// * Tipo para los eventos de interacción
interface EventoInteraccion {
  tipo: string;
  timestamp: string;
  detalle: Record<string, unknown>;
}

// * Estado centralizado para las interacciones
const interaccionesRef = ref<Interacciones>({});
const interacciones = computed(() => unref(interaccionesRef));
const interaccionActual = ref<Interaccion | null>(null);
const tamanoPantalla = ref<TamanoPantalla | null>(null);
let inicializado = false; // * Flag para evitar la doble inicialización de listeners
const idRutaActual = ref<string | null>(null); // * ID único para la ruta actual

export function useRegistroInteracciones() {
  const router = useRouter();
  const route = useRoute();

  /**
   * Actualiza el tamaño de la pantalla al cambiar de tamaño
   */
  const actualizarTamanoPantalla = () => {
    if (typeof window !== 'undefined') {
      tamanoPantalla.value = { ancho: window.innerWidth, alto: window.innerHeight };
      if (interaccionActual.value) {
        interaccionActual.value.tamanoPantalla = { ancho: tamanoPantalla.value.ancho, alto: tamanoPantalla.value.alto };
      }
    }
  };

  /**
   * Registra un evento en la interacción actual
   * @param tipoEvento - Tipo de evento (e.g., 'mousedown', 'touchstart')
   * @param detalleEvento - Detalle adicional del evento
   */
  const registrarEvento = (tipoEvento: string, detalleEvento: Record<string, unknown> = {}) => {
    if (interaccionActual.value) {
      interaccionActual.value.eventos.push({
        tipo: tipoEvento,
        timestamp: new Date().toISOString(),
        detalle: detalleEvento,
      });
    }
  };

  // * Manejadores para eventos de interacción (touch y mouse)
  const manejarTouchStart = (evento: TouchEvent) => registrarEvento('touchstart', { toques: evento.touches.length });
  const manejarTouchMove = (evento: TouchEvent) => registrarEvento('touchmove', { toques: evento.touches.length });
  const manejarMouseDown = (evento: MouseEvent) => registrarEvento('mousedown', { boton: evento.button });
  const manejarMouseUp = (evento: MouseEvent) => registrarEvento('mouseup', { boton: evento.button });

  /**
   * Registra tanto el fin de la ruta anterior como el inicio de la nueva
   * @param rutaAnterior - La ruta anterior (opcional)
   * @param rutaActual - La ruta actual
   */
  const registrarCambioRuta = async (rutaAnterior: RouteLocationNormalized | null, rutaActual: RouteLocationNormalized) => {
    // * Finalizar la interacción de la ruta anterior si existe
    if (interaccionActual.value) {
      interaccionActual.value.horaFin = new Date().toISOString();
      almacenarInteraccionesEnRutaActual(); // * Almacenar antes del cambio de ruta
    }

    // * Asignar un nuevo ID a la ruta actual
    idRutaActual.value = MiniID();

    // * Iniciar una nueva interacción para la ruta actual
    interaccionActual.value = {
      id: idRutaActual.value,
      ruta: rutaActual.fullPath,
      horaInicio: new Date().toISOString(),
      horaFin: null,
      tamanoPantalla: tamanoPantalla.value || { ancho: 0, alto: 0 }, // * Valor por defecto si aún no está disponible
      eventos: [],
    };
  };

  /**
   * Almacena las interacciones recopiladas hasta el momento en la ruta actual
   */
  const almacenarInteraccionesEnRutaActual = () => {
    if (idRutaActual.value && interaccionActual.value) {
      // * Actualiza el objeto de interacciones usando el idRutaActual como key
      interaccionesRef.value[idRutaActual.value] = { ...interaccionActual.value };
    }
  };

  /**
   * Inicializa los event listeners y registra el primer cambio de ruta
   */
  const inicializarListeners = () => {
    if (!inicializado && typeof window !== 'undefined') {
      // * Solo se ejecuta en el cliente, para evitar la inicialización en el SSR
      window.addEventListener('resize', actualizarTamanoPantalla);
      window.addEventListener('touchstart', manejarTouchStart);
      window.addEventListener('touchmove', manejarTouchMove);
      window.addEventListener('mousedown', manejarMouseDown);
      window.addEventListener('mouseup', manejarMouseUp);

      router.beforeEach((to, from, next) => {
        // * Registrar el cambio de ruta
        registrarCambioRuta(from.fullPath ? from : null, to);
        next();
      });

      // * Registrar la primera ruta actual
      registrarCambioRuta(null, route);

      inicializado = true; // * Marcamos como inicializado
    }
  };

  /**
   * Vacía el registro de interacciones
   */
  const vaciarRegistro = () => {
    interaccionesRef.value = {};
    interaccionActual.value = null;
  };

  /**
   * Devuelve el registro de interacciones
   * @returns El registro de interacciones almacenadas
   */
  const obtenerRegistro = () => {
    almacenarInteraccionesEnRutaActual(); // * Asegurarse de almacenar antes de devolver el registro
    return interacciones.value; // * Devolver el objeto directamente, sin JSON.stringify
  };

  // * Computed para obtener el registro actual de interacciones
  const obtenerInteracciones = computed(() => interacciones.value);
  const obtenerInteraccionActual = computed(() => interaccionActual.value);

  // * Inicializar los listeners solo en el cliente, cuando el DOM esté disponible
  function init () {
    inicializarListeners();
    actualizarTamanoPantalla(); // * Asegurarse de capturar el tamaño de pantalla una vez montado
  };

  return {
		init,
    obtenerInteracciones,
    obtenerInteraccionActual,
    obtenerRegistro,
    vaciarRegistro,
    almacenarInteraccionesEnRutaActual, // * Exponer la función para almacenar las interacciones
  };
}
